import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Icon, Popup } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import CurrencyInput from 'react-currency-input';
import { __ } from '../../i18n';

import Form from '../../components/ui/Form';
import Calendar from '../../components/Calendar';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import BetaButton from '../../components/ui/Button';
import BetaModal from '../../components/ui/Modal';
import SingleAccordion from '../../components/SingleAccordion';
import Switch from '../../components/ui/Switch';

import * as utils from '../../utils';

import {
  calculateAbsorbedAmount, calculateInstallments, isBoletoMethodIncluded, isCardMethodIncluded, isPixMethodIncluded
} from '../../lib/payment';

const styles = {
  checkboxMobile: {
    justifyContent: 'space-between',
    margin: '24px 0px 24px 0px',
  }
};

const getInstallments = (account, amount) => {
  const installments = calculateInstallments(account, amount);
  return installments.map((installmentValue, i) => ({ text: `${(i + 1)} x ${installmentValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`, value: (i + 1), key: i }))
};

const date = new Date();

// Fix autofocus issues with CurrencyInput
// on iOS it will still auto focus even if autoFocus=false
// see https://github.com/jsillitoe/react-currency-input/issues/90
const componentDidMount_super = CurrencyInput.prototype.componentDidMount;
CurrencyInput.prototype.componentDidMount = function () {
  if (!this.props.autoFocus) {
    const setSelectionRange_super = this.theInput.setSelectionRange;
    this.theInput.setSelectionRange = () => { };
    componentDidMount_super.call(this, ...arguments);
    this.theInput.setSelectionRange = setSelectionRange_super;
  } else {
    componentDidMount_super.call(this, ...arguments);
  }
};

const componentDidUpdate_super = CurrencyInput.prototype.componentDidUpdate;
CurrencyInput.prototype.componentDidUpdate = function () {
  if (!this.props.autoFocus) {
    const setSelectionRange_super = this.theInput.setSelectionRange;
    this.theInput.setSelectionRange = () => { };
    componentDidUpdate_super.call(this, ...arguments);
    this.theInput.setSelectionRange = setSelectionRange_super;
  } else {
    componentDidMount_super.call(this, ...arguments);
  }
};

@inject('store')
@graphql(gql`query ChargeFormQuery ($entityId: ID!) {
  organization: node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
    ... on Entity {
      id: dbId
      organization {
        id: dbId
        currency
        paymentMethodList {
          nodes {
            name
            splitConfiguration {
              nodes {
                installment
                fee
                feeType
              }
            }
          }
        }
        accounts {
          nodes {
            id: dbId
            absorbedFee
            bank
            currency
            legalName
            rates
          }
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    variables: {
    entityId: (ownProps.params && ownProps.params.entity_id) || ownProps.entity_id,
    }
    })
  })
@observer
export default class ChargeForm extends Form {
  defaultValues = {
    organizationAccountId: undefined,
    currency: undefined,
    name: '',
    amount: 0,
    allowedInstallments: 1,
    description: '',
    hasDate: true,
    dateLimit: new Date(date.getTime() + (30 * 86400000)),
    absorbBoleto: false,
    absorbCard: false,
    absorbPix: false,
    paymentMethods: []
  }

  rules = {
    name: ['required', values => this.validateContent('name', values)],
    amount: ['required', values => this.validateContent('amount', values)],
    organizationAccountId: ['required'],
    paymentMethods: ['required', values => this.validateContent('paymentMethods', values)]
  }

  constructor(props) {
    super(props);
    this.currency = {
      BRL: 'R$',
      USD: 'US$'
    };
  }

  beforeSubmit = (values) => {
    const { organizationAccountId } = values;
    values.currency = this.props.data.organization.organization.accounts.nodes.filter(account => account.id === organizationAccountId)[0] && this.props.data.organization.organization.accounts.nodes.filter(account => account.id === organizationAccountId)[0].currency;
    if (!values.dateLimit) {
      values.dateLimit = new Date(date.getTime() + (30 * 86400000));
    }
    return values;
  }

  componentDidMount() {
    const { values } = this.props;
    const { paymentMethods } = this.props.store.currentOrganization;
    const allowedMethods = paymentMethods.map(method => method.name);

    if (values) {
      if (values.paymentMethods) values.paymentMethods = values.paymentMethods.map(item => item && item.toUpperCase());
      else values.paymentMethods = allowedMethods;

      values.absorbBoleto = values.absorbBoleto !== undefined ?
        values.absorbBoleto :
        (values.absorbedFee && values.absorbedFee.includes('BOLETO'));
      values.absorbCard = values.absorbCard !== undefined ?
        values.absorbCard :
        (values.absorbedFee && values.absorbedFee.includes('CARD'));
      values.absorbPix = values.absorbPix !== undefined ?
        values.absorbPix :
        (values.absorbedFee && values.absorbedFee.includes('PIX'));
      values.allowedInstallments = values.allowedInstallments;
      this.setState({ values });
    } else {
      this.setState({ values: { ...this.state.values, paymentMethods: allowedMethods } });
    }
  }

  validateContent = (name, values) => {
    if (name === 'name') return values.trim().length > 0;

    if (name === 'amount' && values < 2.90) throw new Error(__('The minimum charge amount is R$2,90'));

    if (name === 'paymentMethods' && values.length <= 0) throw new Error(__('Required field'));

    return this.state.values.hasDate;
  }

  onHasDateChange = () => {
    this.setValue('hasDate', !this.state.values.hasDate, () => {
      if (this.state.values.hasDate) {
        this.setValue('dateLimit', new Date(date.getFullYear(), date.getMonth(), date.getDate()));
      } else {
        this.setValue('dateLimit', null);
      }
    });
  }

  onChange = (event) => {
    this.setState({
      amount: event.target.value
    });
  }

  onInputChangeLimitCharacters = (e, { name, value, maxDigits }) => {
    this.onTextInputChange(e, { name, value, maxDigits });
  }


  getPaymentMethodsOptions = () => {
    const { paymentMethods } = this.props.store.currentOrganization;
    const allowedMethods = paymentMethods.map(method => method.name);
    const methods = [];

    if (allowedMethods.includes('CARD')) methods.push({ key: 'CARD', text: __('Credit Card'), value: 'CARD', absorbVarName: 'absorbCard' });
    if (allowedMethods.includes('PIX')) methods.push({ key: 'PIX', text: __('Pix'), value: 'PIX', absorbVarName: 'absorbPix' });
    if (allowedMethods.includes('BOLETO')) methods.push({ key: 'BOLETO', text: __('Bank Boleto'), value: 'BOLETO', absorbVarName: 'absorbBoleto' });

    return methods;
  };

  getBanks = () => this.props.data && this.props.data.organization && this.props.data.organization.organization.accounts && this.props.data.organization.organization.accounts.nodes && this.props.data.organization.organization.accounts.nodes.map((bank, key) =>
    ({ text: bank.legalName, value: bank.id, key, 'data-id': bank.id }))

  getPaymentMethods = (paymentMethods) => {
    if (!paymentMethods) return {
      CARD: { feeString: '' },
      BOLETO: { feeString: '' },
      PIX: { feeString: '' }
    };
    let paymentMethodList = {};
    for (let paymentMethod of paymentMethods.nodes) {
      const { installment, fee, feeType } = paymentMethod.splitConfiguration.nodes[0];
      paymentMethodList[paymentMethod.name] = {
        installment,
        fee,
        feeType,
        feeString: (feeType === 'PERCENTAGE') ? `${fee}%` : `${fee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
      };
    }
    return paymentMethodList;
  }

  getPrefix = () => {
    const { data } = this.props;
    const { organizationAccountId } = this.state.values;
    const organizationCurrency = data && data.organization && data.organization.organization && data.organization.organization.currency;

    if (organizationAccountId !== undefined) {
      const currency = data && data.organization && data.organization.organization && data.organization.organization.accounts && data.organization.organization.accounts.nodes.length > 0 && data.organization.organization.accounts.nodes.filter(account => account.id === organizationAccountId)[0].currency;
      return this.currency[currency];
    }
    if (organizationCurrency) {
      return this.currency[organizationCurrency];
    }
    const isSamePrefix = data && data.organization && data.organization.organization && data.organization.organization.accounts && data.organization.organization.accounts.nodes.length > 0 && data.organization.organization.accounts.nodes.filter(a => a.currency === data.organization.organization.accounts.nodes[0].currency).length === data.organization.organization.accounts.nodes.length;
    if (isSamePrefix) {
      const currency = data.organization.organization.accounts.nodes[0].currency;
      return this.currency[currency];
    }
    return '';
  }

  handleProps = () => {
    const newProps = { ...this.props };
    newProps.submitButton = {
      content: __('Save'),
      disabled: this.props.data && this.props.data.organization && this.props.data.organization.organization && this.props.data.organization.organization.accounts && this.props.data.organization.organization.accounts.nodes && this.props.data.organization.organization.accounts.nodes.length === 0,
      isActionButtom: true
    };

    return newProps;
  }
  handleSelectionChange = (e, { name, value }, cb) => {
    if (value) {
      const account = this.props.data.organization.organization.accounts.nodes.filter(node => node.id === value);
      if (account) {
        this.setValue('absorbBoleto', account[0] && account[0].absorbedFee && isBoletoMethodIncluded(account[0].absorbedFee));
        this.setValue('absorbCard', account[0] && account[0].absorbedFee && isCardMethodIncluded(account[0].absorbedFee));
        this.setValue('absorbPix', account[0] && account[0].absorbedFee && isPixMethodIncluded(account[0].absorbedFee));
      }
    }
    this.setValue(name, value, cb);
  }

  togglePaymentMethod = (pm) => {
    const { values } = this.state;
    if (values.paymentMethods.indexOf(pm.value) !== -1) {
      const newpPaymentMethods = [...values.paymentMethods];
      newpPaymentMethods.splice(values.paymentMethods.indexOf(pm.value), 1);
      this.setValue('paymentMethods', newpPaymentMethods);
      this.setValue(pm.absorbVarName, false);
    } else {
      this.setValue('paymentMethods', values.paymentMethods.concat([pm.value]));
    }
  }

  onError = (errors) => {
    if (errors.name && this.nameView) {
      return this.nameView.scrollIntoView({ behavior: 'smooth' });
    }
    if (errors.amount && this.amountView) {
      return this.amountView.scrollIntoView({ behavior: 'smooth' });
    }
    if (errors.organizationAccountId && this.organizationAccountIdView) {
      return this.organizationAccountIdView.scrollIntoView({ behavior: 'smooth' });
    }
    if (errors.paymentMethods && this.paymentMethodsView) {
      return this.paymentMethodsView.scrollIntoView({ behavior: 'smooth' });
    }
  }

  render() {
    const { values, errors } = this.state;
    const { paymentMethods } = this.props.store.currentOrganization;
    const allowedMethods = paymentMethods.map(method => method.name);

    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);
    const banks = this.getBanks() || [];

    let selectedAccount;
    if (this.props.data && this.props.data.organization) {
      [selectedAccount] = this.props.data.organization.organization.accounts.nodes.filter(node => node.id === this.state.values.organizationAccountId);
    }

    const paymentMethodList = this.getPaymentMethods(this.props?.data?.organization?.organization?.paymentMethodList);

    const absorbedFee = [];
    if (values.absorbCard) absorbedFee.push('CARD');
    if (values.absorbBoleto) absorbedFee.push('BOLETO');
    if (values.absorbPix) absorbedFee.push('PIX');
    const finalAmount = calculateAbsorbedAmount(paymentMethodList, values.amount, absorbedFee);

    const actionButtonsLeft = [<BetaButton
      data-action="cancel"
      round
      transparent
      floated="left"
      text={__('Cancel')}
      onClick={() => this.props.store.appends.pop()}
    />];
    const actionButtonsRight = [<BetaButton
      data-action="submit"
      round
      icon={{ name: 'check' }}
      text={this.props.values !== undefined ? __('Save') : __('Add')}
      disabled={this.props.data && this.props.data.organization && this.props.data.organization.organization && this.props.data.organization.organization.accounts && this.props.data.organization.organization.accounts.nodes && this.props.data.organization.organization.accounts.nodes.length === 0}
      onClick={this.handleSubmit}
    />];

    const format = text => utils.parseFormattedTranslation({
      text,
      props: {
        style: {
          fontWeight: 'bold'
        }
      }
    });

    return (
      <Form
        id="ChargeForm"
        {...this.props}
        actionButtonsLeft={!this.isMobile() ? actionButtonsLeft : null}
        actionButtonsRight={!this.isMobile() ? actionButtonsRight : null}
        {...this.handleProps()}
        className={`charge-form ${this.props.className || ''}${this.isMobile() ? ' mobile' : ''}`}
        paddingHeader={this.isMobile()}
        header={this.isMobile() ? {
          title: '',
          onClose: () => this.props.store.appends.pop(),
          invertCloseButton: true,
          headerItem: <BetaButton
            data-action="submit"
            round
            text={this.props.values !== undefined ? __('Save') : __('Add')}
            disabled={this.props.data
                && this.props.data.organization
                && this.props.data.organization.organization
                && this.props.data.organization.organization.accounts
                && this.props.data.organization.organization.accounts.nodes
                && this.props.data.organization.organization.accounts.nodes.length === 0}
            onClick={this.handleSubmit}
            style={{ height: '40px', width: '100px', justifyContent: 'center', display: 'flex' }}
          /> } : null}
        onSubmit={this.handleSubmit}
        style={this.isMobile() ? { paddingLeft: '24px', paddingRight: '24px' } : {}}
      >
        <div ref={(el) => { this.nameView = el; }}>
          <Form.Input
            label={__('Charge title')}
            placeholder={__('Type your charge\'s title...')}
            name="name"
            red={errors && errors.name && errors.name !== 'Error'}
            value={values.name}
            autoFocus
            style={{ width: '100%' }}
            error={errors && errors.name && __('Required field')}
            onChange={e => this.onInputChangeLimitCharacters(e, { name: 'name', value: e.target.value, maxDigits: 255 })}
          />
        </div>

        <p className="charge-form-label">
          {__('Description ')}
          <span style={{ color: '#868e96', fontSize: '16px' }}> {__('(Optional)')}</span>
        </p>
        <div>
          <Form.Field>
            <TextareaAutosize
              style={{ minHeight: '100px' }}
              maxRows={12}
              placeholder={__('This charge description is for internal management only and is not displayed for the payer') + '...'}
              name="description"
              value={values.description}
              onChange={e => this.setState({ values: { ...values, description: e.target.value } })}
            />
          </Form.Field>
        </div>

        <div style={{ display: 'flex', ...(this.isMobile() ? { flexDirection: 'column' } : { flexDirection: 'row', marginBottom: 0 }) }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: (this.isMobile() ? '100%' : '50%') }}>
            <p className="charge-form-label" ref={(el) => { this.amountView = el; }}>
              {__('Amount')}
            </p>

            <CurrencyInput
              style={{
                height: '47px',
                fontSize: '16px',
                border: errors && errors.amount ? '1px solid rgb(191, 38, 0)' : '1px solid rgba(34,36,38,.15)',
                color: this.state.values.amount ? '#000000' : '#999999',
              }}
              decimalSeparator=","
              thousandSeparator="."
              prefix={this.getPrefix()}
              precision={2}
              value={this.state.values.amount}
              onChangeEvent={(event, stringValue, floatValue) => this.setState({ values: { ...values, amount: floatValue } })}
            />
          </div>

          {errors && errors.amount && this.isMobile() && (
            <div className="error-warning-red">
              <Icon className="circle exclamation" />
              {__('The minimum charge amount is R$2,90')}
            </div>
          )}

          {this.isMobile() ?
            <Form.Group style={{ alignItems: 'flex-end', marginRight: 0, marginLeft: 0, marginTop: 28 }}>
              <div
                onClick={() => this.props.store.appends.push(<BetaModal
                  toast
                  id={'ChargeExpirationDateToast'}
                  header={__('Expiration Date')}
                  closeOnRootNodeClick
                  invertCloseButton
                  scrolling
                  onClose={() => this.props.store.appends.pop()}
                  content={
                    <Calendar
                      withPadding
                      calendarStyles
                      selectedDt={values.dateLimit}
                      onSelect={(d) => { this.setValue('dateLimit', d.selectedDt); this.props.store.appends.pop(); }}
                      maxDate={values.date}
                    />
                  }
                />)}
                style={{ width: '100%' }}
              >
                <Form.Input
                  label={__('Expiration Date')}
                  readOnly
                  icon={{ after: { name: 'chevron down', style: { fontSize: '16px' } } }}
                  error={errors && errors.dateLimit}
                  value={utils.simpleDate(values.dateLimit, true, 'll', lang)}
                />
              </div>
            </Form.Group>
            :
            <Form.Group style={{ alignItems: 'flex-end', marginRight: 0, marginLeft: 16, width: '50%' }}>
              <Popup
                trigger={(
                  <div style={{ width: '100%' }}>
                    <Form.Input
                      className="calendar-input"
                      label={__('Expiration Date')}
                      readOnly
                      icon={{ after: { name: 'chevron down', style: { fontSize: '16px' } } }}
                      error={errors && errors.dateLimit}
                      value={utils.simpleDate(values.dateLimit, true, 'll', lang)}
                      style={{ width: '100%' }}
                    />
                  </div>
                )}
                content={<Calendar
                  calendarStyles
                  selectedDt={values.dateLimit}
                  onSelect={d => this.setValue('dateLimit', d.selectedDt)}
                />}
                position="bottom left"
                hideOnScroll
                on="click"
              />
            </Form.Group>
          }
        </div>

        {errors && errors.amount && !this.isMobile() && (
          <div className="error-warning-red" style={{ marginTop: 0 }}>
            <Icon className="circle exclamation" />
            {__('The minimum charge amount is R$2,90')}
          </div>
        )}

        <div className={`bank-dropdown ${this.isMobile() ? 'mobile' : ''}`} ref={(el) => { this.organizationAccountIdView = el; }}>
          <Form.Dropdown
            className={'form-dropdown'}
            name="organizationAccountId"
            label={__('Account')}
            icon={'chevron down'}
            compact
            style={(errors && !!errors.organizationAccountId) ? { border: '1px solid #bf2600' } : {}}
            selection
            placeholder={banks && banks.length > 0 && __('Select')}
            value={values.organizationAccountId}
            disabled={banks && banks.length === 0}
            text={banks && banks.length === 0 && (this.props.data.loading
              ? __('Loading')
              : __('No banks added. Request for a bank registration to your institution administrator')
            )}
            options={banks}
            onChange={this.handleSelectionChange}
          />
          {errors && errors.organizationAccountId && (
            <div className="error-warning-red">
              <Icon className="circle exclamation" />
              {errors.organizationAccountId}
            </div>
          )}
        </div>

        <SingleAccordion
          title={__('Advanced')}
          customTag={allowedMethods.includes('PIX') && (
            <span className="new-tag">
              <Icon className="icon stars" />
              {__('New!')}
            </span>)}
        >
          <div className={`advanced-settings${this.isMobile() ? '' : ' columns-view'}`}>
            <div>
              <div style={{ display: 'flex' }} ref={(el) => { this.paymentMethodsView = el; }}>
                <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  {__('Payment methods')}
                </p>
              </div>
              {this.getPaymentMethodsOptions().map(pm =>
                <div
                  style={this.isMobile() ? styles.checkboxMobile : {}}
                  key={`method-${pm.value}`}
                >
                  <Form.Field
                    className="last-item"
                    style={{ display: 'inline-flex', alignItems: 'center' }}
                  >
                    <ColoredCheckbox
                      radio
                      radioChecked
                      style={{ fontSize: '16px', display: 'flex' }}
                      bold={false}
                      label={pm.text}
                      name={pm.value}
                      checked={values.paymentMethods.indexOf(pm.value) !== -1}
                      onClick={() => this.togglePaymentMethod(pm)}
                      color="#084FFF"
                    />
                    {pm.value === 'PIX' &&
                      <span className="new-tag">
                        <Icon className="icon stars" />
                        {__('New!')}
                      </span>
                    }
                  </Form.Field>
                </div>
              )}
              {errors && errors.paymentMethods && (
                <div className="error-warning-red" style={{ marginTop: '-15px' }}>
                  <i className="icon circle exclamation" />
                  {errors.paymentMethods}
                </div>
              )}
            </div>

            <div>
              <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                {__('Do you want to absorb the fees?')}
                <Popup
                  basic
                  trigger={<Icon name="info circle" style={{ paddingLeft: 5, color: '#868e96' }} />}
                >
                  <Popup.Content style={{ fontSize: 15 }}>
                    <p>{__('Each payment method has an associated fee:')}</p>

                    <p style={{ marginTop: 21 }}>{format(__('**Credit Card** %s of the value', paymentMethodList.CARD.feeString ))}</p>
                    <p>{format(__('**Boleto** %s', paymentMethodList.BOLETO.feeString))}</p>
                    <p>{format(__('**Pix** %s', paymentMethodList.PIX.feeString))}</p>

                    <p style={{ marginTop: 21 }}>{format(__('If you want to take over and absorb the fees, switch to the **Yes** option.'))}</p>
                    <p>{format(__('If you want to pass on the fees to the payer, change the Fee Absorption field to **No**.'))}</p>
                  </Popup.Content>
                </Popup>
              </p>
              {this.getPaymentMethodsOptions().map(pm => (
                <div
                  style={this.isMobile() ? styles.checkboxMobile : {}}
                  key={`absorb-${pm.value}`}
                >
                  <Form.Field className="last-item">
                    {this.isMobile() ? (
                      <ColoredCheckbox
                        radio
                        radioChecked
                        data-value={0}
                        key={0}
                        style={{ fontSize: '16px', display: 'flex' }}
                        bold={false}
                        label={pm.text}
                        checked={!!values[pm.absorbVarName]}
                        onClick={() => this.setState((prevState) => {
                          prevState.values[pm.absorbVarName] = !values[pm.absorbVarName];
                          return prevState;
                        })}
                        disabled={!values.paymentMethods.includes(pm.value)}
                        color="#084FFF"
                      />
                    ) : (
                      <Switch
                        left={__('No')}
                        right={__('Yes')}
                        toggled={!!values[pm.absorbVarName]}
                        onToggle={() => this.setState((prevState) => {
                          prevState.values[pm.absorbVarName] = !values[pm.absorbVarName];
                          return prevState;
                        })}
                        disabled={!values.paymentMethods.includes(pm.value)}
                        compact
                      />
                    )}
                  </Form.Field>
                </div>
              ))}
            </div>

            <Form.Dropdown
              className="form-dropdown"
              name="allowedInstallments"
              label={__('Payment in up to')}
              icon={'chevron down'}
              compact
              selection
              placeholder={banks && banks.length > 0 && __('Select')}
              value={values.allowedInstallments || this.defaultValues.allowedInstallments}
              options={getInstallments(selectedAccount, finalAmount.card)}
              onChange={(e, { name, value }) => this.onSelectionChange(e, { name, value })}
              disabled={!values.paymentMethods || !values.paymentMethods.includes('CARD')}
            />
          </div>
        </SingleAccordion>
      </Form>
    );
  }
}
