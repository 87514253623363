import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Card, Icon, Dropdown, Popup } from 'semantic-ui-react';
import gql from 'graphql-tag';
import * as utils from '../../utils';
import { __ } from '../../i18n';

import Modal from '../../components/ui/Modal';
import Responsive from '../../components/Responsive';
import OptionsToast from '../../components/OptionsToast';

@inject('store') @observer
export default class ChargeItem extends Responsive {
  constructor(props) {
    super(props);
    this.currency = {
      BRL: 'R$',
      USD: 'US$'
    };
  }

  renderLimitDate = (dateLimit) => {
    const dateString = new Date(utils.parseUniversalDateFormat(dateLimit));
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    return utils.simpleDate(dateString, true, 'LL', lang);
  }
  renderMenu = () => {
    const menu = [
      !this.props.isMessageToApprove && { dataAction: 'edit', text: __('Edit'), icon: 'edit', color: '#000000', closeOnClick: true, onClick: () => { this.props.onEdit(); } },
      { dataAction: 'delete', text: __('Delete Charge'), icon: 'trash', color: '#BF2600', closeOnClick: true, onClick: () => { this.props.onDelete(); } },
      { dataAction: 'cancel', text: __('Cancel'), icon: 'times', color: '#000000', closeOnClick: true },
    ];
    return (
      this.props.store.appends.push(<Modal
        id="OptionsToast"
        toast
        invertCloseButton
        onClose={() => this.props.store.appends.pop()}
        closeOnRootNodeClick
        header={__('Charge Options')}
        spaceFooter
        content={<OptionsToast menu={menu} />}
      />));
  }

  render() {
    const { charge, id } = this.props;

    const { name, amount, dateLimit, key, currency } = charge;

    let items = [];

    items = items.concat([
      !this.props.isMessageToApprove && (
      <Dropdown.Item
        onClick={() => this.props.onEdit()}
      >
        {__('Edit')}
      </Dropdown.Item>
      ),
      <Dropdown.Item
        onClick={() => this.props.onDelete()}
      >
        {__('Delete')}
      </Dropdown.Item>
    ]);

    return (
      <Card
        data-type="ChargeItem"
        key={key}
        data-index={id}
        fluid
        style={{
          marginBottom: '1em',
          width: this.isMobile() ? '100%' : '355px',
          height: this.isMobile() ? '' : '159px',
          borderRadius: '12px',
          marginRight: '8px'
        }}
      >
        <Card.Content style={{ margin: 0 }}>
          <Container textAlign="center">
            <div className="horizontallySpacedItems" style={{ margin: this.isMobile() ? '22px 10px 14px' : '22px 24px 14px' }}>
              <div className="bold"><Icon name="donate" style={{ color: '#000000', marginRight: '12px', fontSize: '16px', fontWeight: 300 }} />
                <Popup
                  trigger={<span>{(name.length > 25) ? (name.substring(0, 25) + '...') : name}</span>}
                  content={<span>{name}</span>}
                  hideOnScroll
                />
              </div>
              {
                 (items.length > 0) ?
                  !this.isMobile() ?
                    <Dropdown data-testid="charge-edit-menu" icon={null} trigger={<Icon name="ellipsis v" style={{ margin: 0 }} />}>
                      <Dropdown.Menu className="bold">
                        {items}
                      </Dropdown.Menu>
                    </Dropdown>
                    :
                    <Icon data-testid="charge-edit-menu" name="ellipsis v" onClick={() => this.renderMenu()} style={{ margin: 0 }} />
                  :
                  null
              }
            </div>

            <div id="ChargeItemDetails" style={{ margin: this.isMobile() ? '0 18px' : '0 32px', marginBottom: dateLimit ? '14px' : '24px' }}>
              <div
                style={{
                  borderRadius: '200px',
                  backgroundColor: '#ffffff',
                  display: 'table',
                  width: '100%',
                  border: '1px solid #084FFF99'
                }}
              >
                <p style={{ fontSize: '13px', fontWeight: '700', padding: '12px', color: '#084FFF', display: 'table-cell', verticalAlign: 'middle' }}>
                  {amount && `${amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                </p>
              </div>
            </div>
            {
              dateLimit &&
                <p style={{ color: '#ffb43c', fontSize: '13px', fontWeight: 300, marginBottom: '24px' }}>
                  <Icon name="exclamation circle" />
                  {
                    __('Expires %s', this.renderLimitDate(dateLimit))
                  }
                </p>
            }
          </Container>
        </Card.Content>
      </Card>
    );
  }
}
