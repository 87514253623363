import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import ChargeForm from './Form';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`query ChargeEditQuery ($id: ID!) {
    charges: node(id: $id) @connection(key: "Message", filter: ["id"]) {
      ... on Message {
        entityId
        charges {
          nodes {
            id: dbId
            name
            amount
            description
            dateLimit
            message {
              subject
            }
          }
        }
      }
    }
    organization: node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        organization {
          accounts {
            nodes {
              id: dbId
              bank
              bankName
            }
          }
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.charges.id,
      entityId: ownProps.params.entity_id
    }
  })
})
@graphql(gql`mutation updateCharge($updateCharge: UpdateChargeInput!) {
  updateCharge(input: $updateCharge) {
    charge {
      name
      dateLimit
      message {
        subject
      }
    }
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['MessageNodeOrganizationQuery', 'MessageNodeEntityQuery', 'ChargeEditQuery']
  }
})
@observer
export default class ChargeEdit extends Controller {
  treatValues = (values) => {
    values.name = values.name.trim();

    if (!values.dateLimit) {
      return {
        name: values.name,
        description: values.description ? values.description : '',
        amount: values.amount
      };
    }

    values.message.subject = values.message.subject.trim();

    values.dateLimit = new Date(values.dateLimit.getTime() - (values.dateLimit.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) + ' ';

    return {
      name: values.name,
      description: values.description ? values.description : '',
      amount: values.amount,
      dateLimit: values.dateLimit
    };
  }

  request = (values) => {
    const { store, mutate, charge } = this.props;

    return mutate({
      variables: {
        updateChargeMutation: {
          id: charge.id,
          ...this.treatValues(values)
        }
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('%s was saved', values.name), success: true };
      store.appends.pop();
    });
  }

  render() {
    const { data, store } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { name, description, amount, dateLimit, paymentMethods, absorbedFee, message, } = data.charges;
    const { accounts } = data.organization;

    return (
      <Controller
        id="ChargeEdit"
        modal
        edit
        title={__('Edit charge')}
        form={ChargeForm}
        values={{
          name,
          amount,
          description,
          dateLimit: dateLimit && new Date(dateLimit),
          paymentMethods,
          absorbedFee,
          message
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        onCancel={() => store.appends.pop()}
        onClose={() => store.appends.pop()}
        {...this.props}
      />
    );
  }
}
