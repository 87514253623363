import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import {
  Loader,
  Button,
  Icon,
  Header,
  Dropdown,
  Popup,
  Container,
  Segment,
  Menu,
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';

import { __ } from '../../i18n';
import * as utils from '../../utils';
import banks from '../../lib/banks';

import AccountItem from '../Account/Item';

import ColoredCheckbox from '../../components/ColoredCheckbox';
import Input from '../../components/Input';
import Calendar from '../../components/Calendar';
import Avatar from '../../components/Avatar';
import ListView from '../../components/ListView';
import Page from '../../components/Page';
import TableView from '../../components/TableView';

import { client_id } from '../../api';
import { isAccountManager } from '../../app/helpers/getIsAccountManager';
import { shouldUseNewAccountForm } from '../../Views/Classpay/helpers/shouldUseNewAccountForm';
import AddAccountModal from '../../Views/Classpay/AddAccountModal';
import AccountAdd from '../Account/Add';

const PAGE_SIZE = 40;
const MAX_ACCOUNTS = 5;
const limitOptions = [
  { text: __('Last %s days', 7), value: '7' },
  { text: __('Last %s days', 15), value: '15' },
  { text: __('Last %s days', 30), value: '30' },
  { text: __('Last week'), value: 'week' },
  { text: __('Last month'), value: 'month' },
];

const styles = {
  dropdownItem: {
    height: 65,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    cursor: 'pointer',
  },
};
@inject('store')
@graphql(
  gql`
    query EntityBankAccountsQuery(
      $organizationId: ID!
      $limit: Int
      $search: String
      $offset: Int
      $dateIntervalLimit: Int
      $dateIntervalOffset: Int
      $accountsLimit: Int
      $accountsOffset: Int
    ) {
      node(id: $organizationId)
        @connection(key: "Organization", filter: ["organizationId"]) {
        ... on Organization {
          id: dbId
          accounts(
            limit: $accountsLimit
            offset: $accountsOffset
            pendingCreation: true
          ) {
            totalCount
            nodes {
              ...AccountItem
            }
          }
          withdrawals(
            limit: $limit
            offset: $offset
            search: $search
            dateInterval: {
              interval: DAY
              limit: $dateIntervalLimit
              offset: $dateIntervalOffset
            }
          ) {
            totalCount
            nodes {
              id: dbId
              amount
              created
              entity {
                id: dbId
                fullname
                picture {
                  uri
                  id: dbId
                  key
                }
              }
              user {
                id: dbId
                fullname
              }
              organizationAccount {
                id: dbId
                bankName
                legalName
                currency
              }
            }
          }
        }
      }
    }
    ${AccountItem.fragments.account}
  `,
  {
    options: (ownProps) => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      variables: {
        organizationId: ownProps.params.organization_id,
        limit: parseInt(ownProps.location.query.limit || PAGE_SIZE, 10),
        offset: parseInt(
          ((ownProps.location.query.p || 1) - 1) *
            (ownProps.location.query.limit || PAGE_SIZE),
          10,
        ),
        search: ownProps.location.query.search || '',
        dateIntervalLimit: parseInt(
          ownProps.location.query.dateIntervalLimit || 30,
          10,
        ),
        dateIntervalOffset: parseInt(
          ownProps.location.query.dateIntervalOffset || 0,
          10,
        ),
        accountsLimit: MAX_ACCOUNTS,
        accountsOffset: 0,
      },
    }),
  },
)
@observer
export default class ChargeAccounts extends Page {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      columns: [
        { value: 'amount', title: __('Amount Withdrawn'), visible: true },
        { value: 'date', title: __('Withdrawal date'), visible: true },
        { value: 'entity', title: __('Entity'), visible: true },
        { value: 'account', title: __('Account'), visible: true },
        { value: 'bank', title: __('Bank'), visible: true },
      ],
      accountManager: false,
      useNewAccountForm: false,
    };
  }

  componentDidMount() {
    const { app, currentUser } = this.props.store;
    if (currentUser.isMaster) {
      isAccountManager(currentUser, app.env).then((result) => {
        this.setState({ accountManager: result });
      });
    }

    shouldUseNewAccountForm(app.env).then((result) => {
      this.setState({ useNewAccountForm: result });
    });
  }

  renderAccount = (account, index) => (
    <AccountItem
      account={account}
      index={index}
      key={index}
      params={this.props.params}
      allBanks={banks}
      accountManager={this.state.accountManager}
    />
  );

  renderAccounts = (addAccountItem) => {
    const { data, store } = this.props;
    const hasAdmin =
      store.currentEntity.type === 'ADMIN' ||
      (store.currentEntity.organization &&
        store.currentEntity.organization.permissions &&
        store.currentEntity.organization.permissions.hasAdmin);

    const hasCreatePermission =
      store.currentEntity.organization &&
      store.currentEntity.organization.permissions &&
      store.currentEntity.organization.permissions.entityScopes.includes(
        'CREATE_UPDATE_ACCOUNT',
      );

    const hasMoreAccounts =
      data &&
      data.node &&
      data.node.accounts &&
      data.node.accounts.totalCount !== data.node.accounts.nodes.length;

    const addBankAccountForm = this.state.useNewAccountForm ? (
      <AddAccountModal
        organizationName={store.currentOrganization.fullname}
        organizationId={store.currentOrganization.id}
        onClose={() => this.props.store.appends.pop()}
      />
    ) : (
      <AccountAdd params={this.props.params} />
    );

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            marginBottom: 30,
            justifyContent: 'flex-end',
          }}
        >
          <Button
            id="addBankAccount"
            circular
            disabled={!(hasAdmin || hasCreatePermission)}
            style={{ backgroundColor: '#fff', border: '1px solid #e9ecef' }}
            onClick={() => this.props.store.appends.push(addBankAccountForm)}
          >
            <Icon name="plus" />
            {__('Add new bank account')}
          </Button>
        </div>
        <ListView
          renderRow={this.renderAccount}
          source={
            data && data.node && data.node.accounts && data.node.accounts.nodes
          }
          segment={false}
          celled={false}
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          {addAccountItem}
        </ListView>
        {hasMoreAccounts && (
          <Button
            basic
            circular
            color="white"
            loading={data.loading}
            disabled={data.loading}
            onClick={() =>
              data.fetchMore({
                variables: { accountsOffset: data.node.accounts.nodes.length },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  if (
                    fetchMoreResult.node &&
                    fetchMoreResult.node.accounts &&
                    fetchMoreResult.node.accounts.nodes
                  ) {
                    fetchMoreResult.node.accounts.nodes = [
                      ...prevResult.node.accounts.nodes,
                      ...fetchMoreResult.node.accounts.nodes,
                    ];
                    return fetchMoreResult;
                  }
                  return prevResult;
                },
              })
            }
            style={{ margin: '0 auto 1rem' }}
          >
            {__('Load more')}
          </Button>
        )}
      </div>
    );
  };

  renderColumns = () => {
    const columns = [];

    if (
      this.state.columns.find((column) => column.value === 'amount').visible
    ) {
      columns.push(
        <Popup
          trigger={<p>{__('Amount Withdrawn')}</p>}
          content={__(
            'The total amount available in the withdrawal is subtracted from the withdrawal fee of R$3,67. For Bradesco banks this withdrawal fee is free.',
          )}
        />,
      );
    }
    if (this.state.columns.find((column) => column.value === 'date').visible)
      columns.push(__('Withdrawal date'));
    if (this.state.columns.find((column) => column.value === 'entity').visible)
      columns.push(__('Entity'));
    if (this.state.columns.find((column) => column.value === 'account').visible)
      columns.push(__('Account'));
    if (this.state.columns.find((column) => column.value === 'bank').visible)
      columns.push(__('Bank'));

    return columns;
  };

  renderRowCells = (withdrawal) => {
    const { created, amount, entity, user, organizationAccount } = withdrawal;
    const { store } = this.props;

    const lang = store.app.locale
      ? store.app.locale === 'pt'
        ? 'pt-BR'
        : store.app.locale
      : 'en';
    const date = utils.simpleDate(created, true, 'L HH:mm', lang);
    const columns = [];

    if (this.state.columns.find((column) => column.value === 'amount').visible)
      columns.push(
        amount.toLocaleString('pt-BR', {
          style: 'currency',
          currency: organizationAccount.currency || 'BRL',
        }),
      );
    if (this.state.columns.find((column) => column.value === 'date').visible)
      columns.push(date);
    if (
      this.state.columns.find((column) => column.value === 'entity').visible
    ) {
      const content =
        entity && user ? (
          <Header
            as="h5"
            className="entityItem"
            title={utils.renderLongText(entity.fullname)}
            data-id={entity.id}
          >
            <Avatar
              avatar
              spaced="right"
              src={entity.picture && entity.picture.uri}
              alt={entity.fullname || ''}
            />
            <Header.Content>
              {entity.fullname ? (
                entity.fullname.length > 18 ? (
                  <Popup
                    trigger={
                      <Link
                        className="bold defaultClspColor"
                        to={`/entities/${entity.id}`}
                      >
                        {entity.fullname.substring(0, 18).trim()}...
                      </Link>
                    }
                    content={entity.fullname}
                  />
                ) : (
                  <Link
                    className="bold defaultClspColor"
                    to={`/entities/${entity.id}`}
                  >
                    {entity.fullname}
                  </Link>
                )
              ) : null}
              <Header.Subheader>
                {user.fullname && user.fullname.length > 18 ? (
                  <Popup
                    trigger={
                      <span>{user.fullname.substring(0, 18).trim()} ...</span>
                    }
                    content={user.fullname}
                  />
                ) : (
                  user.fullname
                )}
              </Header.Subheader>
            </Header.Content>
          </Header>
        ) : (
          <h5>
            {lang === 'en'
              ? __('Automatic Withdrawal')
              : __('Automatic Withdrawal(accounts)')}
          </h5>
        );
      columns.push(content);
    }
    if (this.state.columns.find((column) => column.value === 'account').visible)
      columns.push(utils.renderLongText(organizationAccount.legalName));
    if (this.state.columns.find((column) => column.value === 'bank').visible)
      columns.push(organizationAccount.bankName);

    return columns;
  };

  handleDropdownClick = ({ value }) => {
    let dateIntervalOffset;
    let dateIntervalLimit;
    let filter;

    if (value === 'week') {
      filter = 'week';
      const endOfLastWeek = moment().startOf('week').subtract(1, 'days');
      const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week');
      dateIntervalOffset = moment().diff(endOfLastWeek, 'days');
      dateIntervalLimit = moment().diff(startOfLastWeek, 'days');
    } else if (value === 'month') {
      filter = 'month';
      const endOfLastMonth = moment().startOf('month').subtract(1, 'days');
      const startOfLastMonth = moment().subtract(1, 'month').startOf('month');
      dateIntervalOffset = moment().diff(endOfLastMonth, 'days');
      dateIntervalLimit = moment().diff(startOfLastMonth, 'days');
    }
    this.setState({ filter });
    this.onParameterChange({
      dateIntervalOffset: (dateIntervalOffset || 0).toString(),
      dateIntervalLimit: (dateIntervalLimit || value).toString(),
      custom: false,
    });
  };

  handleCalendarClick = (start, end) => {
    let startDate = start;
    let endDate = end;

    if (endDate && startDate) {
      if (startDate > endDate) {
        startDate = [endDate, (endDate = startDate)][0];
      }

      if (this.dropdown) this.dropdown.close();

      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
      endDate.setSeconds(0);
      endDate.setMilliseconds(0);

      const now = moment().hour(12).minute(0).second(0).millisecond(0);
      const dateIntervalOffset =
        moment(endDate).dayOfYear() === now.dayOfYear()
          ? 0
          : now.diff(moment(endDate), 'days');
      const dateIntervalLimit =
        startDate.getTime() === endDate.getTime()
          ? 1
          : now.diff(moment(startDate), 'days');

      this.onParameterChange({
        dateIntervalOffset: dateIntervalOffset.toString(),
        dateIntervalLimit: dateIntervalLimit.toString(),
        custom: true,
        filter: '',
      });
    }
  };

  onParameterChange = (data) => {
    const parameters = Object.keys(data).map((name) => ({
      name,
      value: data[name],
    }));

    this.onMultipleParametersChange(parameters);
  };

  renderFilter = () => {
    const { data, location } = this.props;
    const { custom, dateIntervalLimit, dateIntervalOffset } = location.query;
    const { filter } = this.state;

    let startDate = new Date(moment().subtract(30, 'days'));
    let endDate = new Date(moment());
    let selectedLimit = { text: __('Last %s days', 30) };

    if (!custom && filter === 'week') {
      const endOfLastWeek = moment().startOf('week').subtract(1, 'days');
      const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week');

      endDate = new Date(endOfLastWeek);
      startDate = new Date(startOfLastWeek);
      selectedLimit = limitOptions.find((option) => option.value === 'week');
    } else if (!custom && filter === 'month') {
      const endOfLastMonth = moment().startOf('month').subtract(1, 'days');
      const startOfLastMonth = moment().subtract(1, 'month').startOf('month');

      endDate = new Date(endOfLastMonth);
      startDate = new Date(startOfLastMonth);
      selectedLimit = limitOptions.find((option) => option.value === 'month');
    } else {
      endDate = new Date(moment().subtract(dateIntervalOffset || 0, 'days'));
      startDate = new Date(moment().subtract(dateIntervalLimit || 30, 'days'));

      if (custom) {
        selectedLimit = {
          text: __('Custom'),
        };
      } else {
        selectedLimit =
          limitOptions[
            limitOptions.findIndex(
              (option) => option.value === dateIntervalLimit,
            )
          ] || limitOptions[2];
      }
    }

    startDate.setHours(12);
    startDate.setMinutes(0);
    startDate.setSeconds(0);

    endDate.setHours(12);
    endDate.setMinutes(0);
    endDate.setSeconds(0);

    return (
      <Container
        key={0}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px 0',
        }}
      >
        <div>
          <Icon name="filter" />
          <strong>{__('Filter by:')}</strong>
          <span style={{ color: '#4a90e2', marginLeft: '10px' }}>
            <Dropdown
              ref={(c) => {
                this.dropdown = c;
              }}
              inline
              data-cy={'open-more-settings'}
              basic
              text={!data.loading && selectedLimit.text}
              loading={data.loading}
            >
              <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
                <div
                  style={{
                    display: 'flex',
                    color: 'black',
                    padding: '4px 0px',
                    borderRadius: '4px',
                    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
                    border: 'solid 1px #e9ecef',
                    backgroundColor: 'white',
                  }}
                >
                  <div style={{ marginBottom: 'auto', marginTop: 'auto' }}>
                    {limitOptions.map((option, i) => (
                      <Dropdown.Item
                        key={i}
                        {...option}
                        style={{
                          ...styles.dropdownItem,
                          backgroundColor:
                            option === selectedLimit
                              ? 'rgba(0, 118, 255, 0.07)'
                              : 'white',
                        }}
                        active={option === selectedLimit}
                        onClick={() => this.handleDropdownClick(option)}
                      />
                    ))}
                  </div>
                  <div
                    style={{
                      padding: '5px 16px',
                      borderLeft: 'solid 1px #e9ecef',
                    }}
                  >
                    <p
                      style={{
                        marginBottom: '8px',
                        marginTop: '4px',
                        fontSize: '1.1em',
                      }}
                    >
                      {__('Custom')}
                    </p>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '10px' }}>
                        <p style={{ marginBottom: '-0.4em' }}>{__('Begin')}</p>
                        <Calendar
                          withBorder
                          withPadding
                          calendarStyles
                          headerAlwaysOn
                          selectedDt={startDate}
                          maxDate={
                            new Date(endDate.getTime() - 24 * 60 * 60 * 1000)
                          }
                          minDate={new Date(2020, 0, 1)}
                          onSelect={(date) =>
                            this.handleCalendarClick(date.selectedDt, endDate)
                          }
                        />
                      </div>
                      <div>
                        <p style={{ marginBottom: '-0.4em' }}>{__('End')}</p>
                        <Calendar
                          withBorder
                          withPadding
                          calendarStyles
                          headerAlwaysOn
                          selectedDt={endDate}
                          maxDate={new Date()}
                          minDate={startDate}
                          onSelect={(date) =>
                            this.handleCalendarClick(startDate, date.selectedDt)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
      </Container>
    );
  };

  renderTableFilter = () => {
    const { columns } = this.state;

    return (
      <div style={{ display: 'flex', marginTop: '14px' }}>
        <Segment
          style={{
            padding: '2px',
            margin: 0,
            border: 0,
            background: 'none',
            boxShadow: 'none',
            width: '100%',
          }}
        >
          <Menu secondary>
            <Menu compact>
              <Dropdown text={__('Columns')} data-params="columns" item>
                <Dropdown.Menu>
                  {columns.map((column, i) => (
                    <Dropdown.Item
                      style={{ display: 'flex' }}
                      onClick={() => {
                        columns[i].visible = !column.visible;

                        this.setState({ columns });
                      }}
                    >
                      <ColoredCheckbox
                        data-value={column.value}
                        label={column.title}
                        checked={column.visible}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
            <Input
              data-cy="search-payment"
              onChange={(e, { value }) =>
                this.onUniqueParameterChange('search', value)
              }
              placeholder={__('Search by entity')}
              style={{ margin: 'auto 3px' }}
              wait
              initialValue={this.props.location.query.search || ''}
            />
          </Menu>
        </Segment>
      </div>
    );
  };
  renderAddFirstBank = () => {
    const { store } = this.props;
    const hasAdmin =
      store.currentEntity.type === 'ADMIN' ||
      (store.currentEntity.organization &&
        store.currentEntity.organization.permissions &&
        store.currentEntity.organization.permissions.hasAdmin);

    const hasCreatePermission =
      store.currentEntity.organization &&
      store.currentEntity.organization.permissions &&
      store.currentEntity.organization.permissions.entityScopes.includes(
        'CREATE_UPDATE_ACCOUNT',
      );

    const addBankAccountForm = this.state.useNewAccountForm ? (
      <AddAccountModal
        organizationName={store.currentOrganization.fullname}
        organizationId={store.currentOrganization.id}
        onClose={() => this.props.store.appends.pop()}
      />
    ) : (
      <AccountAdd params={this.props.params} />
    );

    return (
      <div
        style={{
          display: 'flex',
          height: 180,
          border: '1px solid #e9ecef',
          borderRadius: 8,
          backgroundColor: '#fff',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          marginBottom: 100,
          marginTop: 50,
        }}
      >
        <div>{__('THERE ARE NO REGISTERED BANK ACCOUNTS')}</div>
        <div>{__('Add a new bank account to create charges')}</div>
        <div>
          <Button
            id="addBankAccount"
            circular
            disabled={!(hasAdmin || hasCreatePermission)}
            style={{ backgroundColor: '#fff', border: '1px solid #e9ecef' }}
            onClick={() => this.props.store.appends.push(addBankAccountForm)}
          >
            <Icon name="plus" />
            {__('Add new bank account')}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { data, location, store } = this.props;

    if ((data.loading && !data.node) || !data.node)
      return <Loader active inline="centered" />;
    const query = { ...location.query };
    const lang = store.app.locale
      ? store.app.locale === 'pt-BR'
        ? 'pt'
        : store.app.locale
      : 'en';
    const exportLink =
      `${store.app.url}/csv/organization/withdrawals?client_id=${client_id}&search=${query.search || ''}&` +
      `limit=${query.limit || 40000}&offset=${query.p || 0}&organization_id=${store.currentOrganization.id}&access_token=${encodeURIComponent(store.access_token)}&tz_offset=${-new Date().getTimezoneOffset()}&locale=${lang}&date_interval_limit=${query.dateIntervalLimit || 30}&date_interval_offset=${query.dateIntervalOffset || 0}`;

    return (
      <div
        id="ChargeAccounts"
        style={{ marginTop: 32, marginBottom: 100, marginLeft: 10 }}
      >
        {data &&
        data.node &&
        data.node.accounts &&
        data.node.accounts.nodes &&
        data.node.accounts.nodes.length > 0
          ? this.renderAccounts()
          : this.renderAddFirstBank()}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: 500 }}>
            {this.renderFilter()}
            {this.renderTableFilter()}
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button
              style={{
                height: 40,
                color: '#495057',
                backgroundColor: '#fff',
                border: '1px solid #e9ecef',
                borderRadius: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              as="a"
              href={exportLink}
            >
              <Icon name="download" />
              {__('Export')}
            </Button>
          </div>
        </div>
        <TableView
          style={{ textAlign: 'left' }}
          renderColumns={this.renderColumns()}
          renderRowCells={this.renderRowCells}
          source={data.node.withdrawals.nodes}
          pagination={{
            loading: data.loading,
            totalCount: data.node.withdrawals.totalCount,
            query: location.query,
          }}
          onLoadMore={this.eventReload}
        />
        {this.props.children}
      </div>
    );
  }
}
