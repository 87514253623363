import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import moment from 'moment-timezone';

import { Button, Icon, Grid } from 'semantic-ui-react';
import { statusInfo, paymentMethodsShort } from '../../lib/payment';
import TableView from '../../components/TableView';
import Modal from '../../components/Modal';
import Refund from './Refund';
import Page from '../../components/Page';

import { __ } from '../../i18n';

const styles = {
  roundButton: {
    padding: '10px',
    borderRadius: 26
  },
};
const HeaderBox = props => (
  <div
    style={{
      flex: 1,
      ...props.style
    }}
  >
    {props.children}
  </div>
);
const BoxTitle = props => (
  <p
    style={{
      fontSize: 18,
      fontWeight: 700,
      marginBottom: 1,
      ...props.style
    }}
  >
    {props.children}
  </p>
);
const MainBox = props => (
  <div
    style={{
      width: '95%',
      margin: 'auto',
      marginTop: 12,
      ...props.style
    }}
  >
    {props.children}
  </div>
);
const DetailsContainer = props => (
  <div
    style={{
      borderRadius: 12,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'rgba(0, 0, 0,.16)',
      padding: '16px 16px 0px 16px',
      marginTop: 12,
      ...props.style
    }}
  >
    {props.children}
  </div>
);
const InfoBox = props => (
  <Grid.Row columns={3} style={{ padding: 0 }}>
    {props.children}
  </Grid.Row>
);
const Info = props => (
  <Grid.Column>
    <p style={{ ...props.style }}><b style={{ fontSize: 'medium' }}>{props.title}</b> {props.info}</p>
  </Grid.Column>
);
const displayAmount = (amount, currency, type) => (
  !type || type === 'AMOUNT' ?
    amount.toLocaleString('pt-BR', { style: 'currency', currency }) :
    `${amount}%`
);

@inject('store')
@graphql(gql`query PaymentDetailsQuery($id:ID!, $entityId: ID!) {
  node(id: $id) @connection(key: "Charge", filter: ["id"]) {
    ... on Charge {
      id:dbId
      messageId
      invoiceId
      name
      description
      currency
      paymentMethods
      absorbedFee
      allowedInstallments
      payableExpired
      amount
      hasFine
      hasInterest
      hasAnticipationDiscount
      fineValue
      interestValue
      anticipationDiscountValue
      fineType
      interestType
      anticipationDiscountType
      anticipationDiscountFinalDate
      dateLimit
      account {
        id: dbId
        legalName
        bankName
        methods
      }
      payments(entityId: $entityId){
        nodes {
          id: dbId
          status
          method
          link
          paidDate
          lastStatusUpdate
          externalDetails {
            isExternalSource
            amountToPay
            updatedPaidAmount
            amountToReceive
            installments
            updatedStatus
          }
          payables {
            status
            amount
            installment
            fee
            anticipationFee
            fraudCoverageFee
            hasAnyFee
            liquidAmount
            type
            receiveDate
            createdDate
          }
          user {
            id: dbId
            fullname
          }
          entity {
            id: dbId
            fullname
          }
        }
      }
      message {
        id: dbId
        entityId
      }
    }
  }
}
  `, {
  options: ownProps => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: ownProps.chargeId,
      entityId: ownProps.entityId
    }
  })
})
@observer
export default class PaymentInformation extends Page {
  getSortedPayables = payables => [...payables].sort((a, b) => {
    if (!a || !a.installment) return -1;
    if (!b || !b.installment) return 1;
    return a.installment - b.installment;
  })

  renderChargeInfo = (charge, status) => {
    let currentStatus;
    if (status === 'EXPIRED' && charge.payableExpired) currentStatus = statusInfo.OVERDUE;
    else currentStatus = statusInfo[status];
    return (
      <MainBox>
        <div style={{ display: 'flex' }}>
          <HeaderBox>
            <div style={{ display: 'flex' }}>
              <BoxTitle style={{ maxWidth: '85%' }}>{__('Charge: %s', charge.name)}</BoxTitle>
              <div
                style={{
                  display: 'flex',
                  color: currentStatus.color,
                  border: '1px solid',
                  borderRadius: 12,
                  padding: '3px 10px',
                  fontSize: 12,
                  marginLeft: 5,
                  height: 25
                }}
              >
                <Icon name={currentStatus.icon} style={{ paddingTop: '1px' }} />
                <p>{currentStatus.chargeText}</p>
              </div>
            </div>
            <p>{charge.description ? charge.description : ''}</p>
          </HeaderBox>

          {
            !this.isMobile() && charge && charge.messageId && this.props.store.currentEntity.type === 'ADMIN' && (
              <Button
                basic
                style={{ ...styles.roundButton, height: '40px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                as="a"
                href={`/entities/${this.props.data.node.message.entityId}/messages/${charge.messageId}`}
                target="_blank"
              >
                <Icon name="envelope" />{__('See message')}
              </Button>
            )}

        </div>
        {this.isMobile() && (
          <div>
            {
              charge && charge.messageId && this.props.store.currentEntity.type === 'ADMIN' && (
                <Button
                  basic
                  style={{ ...styles.roundButton, height: 'auto' }}
                  as="a"
                  href={`/organizations/${this.props.store.currentOrganization.id}/messages/${charge.messageId}`}
                >
                  <Icon name="envelope" />{__('See message')}
                </Button>
              )
            }
          </div>
        )}
        <DetailsContainer>
          <Grid style={{ padding: 10 }} stackable>
            <InfoBox>
              <Info title={__('Charge amount:')} info={displayAmount(charge.amount, charge.currency)} />
              <Info
                title={__('Payment methods:')}
                info={charge.paymentMethods ? charge.paymentMethods.map(item => paymentMethodsShort[item]).join(', ') : __('None')}
              />
              <Info title={__('Payable expired:')} info={charge.payableExpired ? __('Yes') : __('No')} />
            </InfoBox>
            <InfoBox>
              <Info title={__('Expiration date:')} info={moment(charge.dateLimit).format('DD/MM/YYYY')} />
              <Info
                title={__('Absorbed fee:')} info={charge.absorbedFee ? charge.absorbedFee.map(item => paymentMethodsShort[item]).join(', ') : __('None')}
              />
              <Info title={__('Allowed installments:')} info={charge.allowedInstallments} />
            </InfoBox>
            <InfoBox>
              <Info
                title={__('Fine:')}
                info={charge.hasFine ? displayAmount(charge.fineValue, charge.currency, charge.fineType) : __('None')}
              />
              <Info
                title={__('Anticipation Discount:')}
                info={charge.hasAnticipationDiscount ?
                  `${displayAmount(charge.anticipationDiscountValue, charge.currency, charge.anticipationDiscountType)} ${__('until')} ${charge.anticipationDiscountFinalDate}`
                  : __('None')}
              />
              <Info
                title={__('Daily interest:')}
                info={charge.hasInterest ? displayAmount(charge.interestValue, charge.currency, charge.interestType) : __('None')}
              />
            </InfoBox>
          </Grid>
        </DetailsContainer>
      </MainBox>
    );
  }

  renderPaymentInfo = (payment, charge) => {
    const updatedPayment = payment.externalDetails;
    return (
      <MainBox>
        <div style={{ display: 'flex', marginTop: 26 }}>
          <HeaderBox>
            <BoxTitle>{__('Payment')}</BoxTitle>
          </HeaderBox>
          <div style={{ display: 'flex' }}>
            {payment && payment.method && payment.method === 'BOLETO' && !!payment.link ?
              <Button
                basic
                style={styles.roundButton}
                as="a"
                href={payment.link}
                target="_blank"
              >
                <Icon name="barcode" />{__('View boleto')}
              </Button> : null
            }
            {payment && payment.method === 'CARD' && payment.status === 'PAID' && !charge.invoiceId ?
              <Button
                basic
                style={styles.roundButton}
                onClick={() => this.props.store.appends.push(<Refund payment={payment} onClose={() => { this.setState({}); }} />)}
              >
                <Icon name="hand holding usd" />
                {__('Refund payment')}
              </Button> : null
            }
          </div>
        </div>
        <DetailsContainer>
          <Grid style={{ padding: 10 }} stackable>
            <InfoBox>
              <Info
                title={__('Student:')}
                info={this.props.recipient && this.props.recipient.entity && this.props.recipient.entity.fullname}
              />
              {(payment.status === 'PAID' || payment.status === 'CANCELED') ?
                <Info
                  title={__('Paid amount:')}
                  info={updatedPayment && updatedPayment.updatedPaidAmount && (payment.status === 'PAID' || payment.status === 'CANCELED') ? displayAmount(updatedPayment.updatedPaidAmount, charge.currency) : ' - '}
                />
                : <Info
                  title={__('Amount to pay:')}
                  info={this.props.recipient ? displayAmount(this.props.recipient.currentAmount, charge.currency) : '-'}
                />
              }
              <Info
                title={__('Payment method:')}
                style={payment && payment.status === 'FAILED' ? { textDecorationLine: 'line-through' } : {}}
                info={payment && payment.method ? paymentMethodsShort[payment.method] : ' - '}
              />
            </InfoBox>
            <InfoBox>
              <Info
                title={payment && payment.method === 'EXTERNAL' ? __('Manual payment registered by:') : __('Accountable payer:')}
                style={payment && payment.status === 'FAILED' ? { textDecorationLine: 'line-through' } : {}}
                info={payment && payment.user && payment.user.fullname ? payment.user.fullname : ' - '}
              />
              <Info
                title={__('Installments:')}
                info={updatedPayment && updatedPayment.installments && payment.status !== 'FAILED' ? updatedPayment.installments : ' - '}
              />

            </InfoBox >
          </Grid >

        </DetailsContainer >
      </MainBox >
    );
  }

  renderIncomeInfo = (payment, charge) => {
    const IncomeHeaderBox = props => (
      <HeaderBox style={{ marginTop: 26 }}>
        {props.children}
      </HeaderBox>
    );

    const updatedPayment = payment && payment.externalDetails;
    const safeReceivedAmount = payment && payment.payables && payment.payables.length && payment.payables.reduce((acc, cur) => acc + cur.amount, 0);

    return (
      <MainBox>
        <IncomeHeaderBox>
          <BoxTitle>{__('Income')}</BoxTitle>
        </IncomeHeaderBox>
        <DetailsContainer>
          <Grid stackable style={{ padding: 10 }}>
            <InfoBox>
              {<Info title={__('Account:')} info={charge.account.legalName} />}
              {payment.method !== 'EXTERNAL' && <Info title={__('Bank:')} info={charge.account.bankName} />}
              <Info
                title={payment && payment.method === 'EXTERNAL' ? __('Manual payment registered in:') : `${__('Approved on')}:`}
                style={payment && payment.status === 'CANCELED' ? { textDecorationLine: 'line-through' } : {}}
                info={payment && payment.paidDate && payment.status !== 'FAILED' ? moment(payment.paidDate).format('DD/MM/YYYY') : ' - '}
              />
            </InfoBox>
            <InfoBox>
              <Info
                title={__('Amount received:')}
                style={payment && payment.status === 'CANCELED' ? { textDecorationLine: 'line-through' } : {}}
                info={updatedPayment && updatedPayment.updatedPaidAmount && payment.status !== 'FAILED' ? displayAmount(updatedPayment.updatedPaidAmount, charge.currency) : ' - '}
              />
              <Info
                title={__('Fee amount:')}
                style={payment && payment.status === 'CANCELED' ? { textDecorationLine: 'line-through' } : {}}
                info={updatedPayment && updatedPayment.updatedPaidAmount && payment.status !== 'FAILED' ? displayAmount(updatedPayment.amountToPay - updatedPayment.amountToReceive, charge.currency) : ' - '}
              />
              <Info
                title={__('Balance amount:')}
                style={payment && payment.status === 'CANCELED' ? { textDecorationLine: 'line-through' } : {}}
                info={safeReceivedAmount && payment.status !== 'FAILED' ? displayAmount(safeReceivedAmount, charge.currency) : ' - '}
              />
            </InfoBox>
          </Grid>
        </DetailsContainer>
        {payment.status !== 'CANCELED' &&
          <div style={{ overflowX: 'scroll', marginTop: 26 }}>
            <TableView
              renderColumns={[__('Balance Installments'), __('Entrance'), __('Balance available in')]}
              renderRowCells={payable => ([
                <div><p>{payable.installment}</p></div>,
                <div><p>{displayAmount(payable.amount, charge.currency)}</p></div>,
                <div><p>{moment(payable.receiveDate).format('DD/MM/YYYY')}</p></div>,
              ])}
              source={payment.payables && payment.payables.length ? this.getSortedPayables(payment.payables) : []}
            />
          </div>
        }
      </MainBox>
    );
  }

  renderCancelInfo = (payment, charge) => {
    if (!payment || payment.status !== 'CANCELED') return (<div />);

    const CancelHeaderBox = props => (
      <HeaderBox style={{ marginTop: 26 }}>
        {props.children}
      </HeaderBox>
    );

    const updatedPayment = payment && payment.externalDetails;

    return (
      <MainBox>
        <CancelHeaderBox>
          <BoxTitle>{__('Reversal')}</BoxTitle>
        </CancelHeaderBox>
        <DetailsContainer>
          <Grid style={{ padding: 10 }} stackable>
            <InfoBox>
              {<Info title={__('Receiver:')} info={payment && payment.user && payment.user.fullname} />}
              <Info title={__('Amount canceled:')} info={updatedPayment && updatedPayment.updatedPaidAmount ? displayAmount(updatedPayment.updatedPaidAmount, charge.currency) : ' - '} />
              <Info title={`${__('Approved on')}:`} info={payment && payment.lastStatusUpdate ? moment(payment.lastStatusUpdate).format('DD/MM/YYYY') : ' - '} />
            </InfoBox>
          </Grid>
        </DetailsContainer>
      </MainBox>
    );
  }

  render() {
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { node: charge } = data;
    const { payments } = charge;
    const payment = payments && payments.nodes && payments.nodes.length && payments.nodes[0];

    const { recipient } = this.props;
    // This is made so the status is updated imediatly after cancelling
    const status = payment.status === 'CANCELED' ? payment.status : recipient.status;

    return (
      <Modal
        id="ChargeStatus"
        open
        size="large"
        closeIcon="close"
        onClose={() => this.props.store.appends.pop()}
      >
        <Modal.Header>
          {__('Revenue Details')}
        </Modal.Header>
        <Modal.Content style={{ overflowY: 'scroll', maxHeight: 550 }} >
          {this.renderChargeInfo(charge, status)}
          {this.renderPaymentInfo(payment, charge)}
          {this.renderIncomeInfo(payment, charge)}
          {this.renderCancelInfo(payment, charge)}
        </Modal.Content>
      </Modal>
    );
  }
}
