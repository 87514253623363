import React from 'react';
import { Link } from 'react-router';
import { Icon, Header, Popup, Dropdown, Segment, Menu, Loader, Button, Container, Card, Grid } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import PaymentInformation from './PaymentInformation';

import Paginator from '../../components/Paginator';
import Calendar from '../../components/Calendar';
import Table from '../../components/Table';
import Avatar from '../../components/Avatar';
import TableActionBar from '../../components/TableActionBar';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import Page from '../../components/Page';
import { __ } from '../../i18n';
import * as utils from '../../utils';
import { client_id } from '../../api';
import BetaModal from '../../components/ui/Modal';
import BetaButton from '../../components/ui/Button';
import CalendarInterval from '../../components/CalendarInterval';
import EmptyImage from '../../components/ui/EmptyImage';
import ExportModal from '../../Views/Shared/ExportModal/ExportModal';
import { createOrganizationPaymentsReportUseCase } from '../../Domain/UseCase/ChargePayment/createOrganizationPaymentsReportUseCase';
import { UnleashAxios } from '../../lib/unleashAxios';

const PAGE_SIZE = 40;

const limitOptions = [
  { text: __('Last %s days', 7), value: '7' },
  { text: __('Last %s days', 15), value: '15' },
  { text: __('Last %s days', 30), value: '30' },
  { text: __('Last week'), value: 'week' },
  { text: __('This month'), value: 'month' },
  { text: __('Last month'), value: 'last-month' }
];
const months = [__('jan'), __('feb'), __('mar'), __('apr'), __('may'), __('jun'), __('jul'), __('aug'), __('sep'), __('oct'), __('nov'), __('dec')];

const defaultInterval = {
  startDate: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
  endDate: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
};

const paymentMethods = () => ({
  CARD: __('Card'),
  BOLETO: __('Boleto'),
  PIX: __('Pix'),
  EXTERNAL: __('Manual')
});

const styles = {
  dropdownItem: {
    height: 65,
    padding: '0px 15px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  }
};

const statusMap = {
  PAID: {
    icon: 'check',
    color: '#37b24d',
    text: __('Paid')
  },
  PENDING: {
    icon: 'clock',
    color: '#FBB23C',
    text: __('To due')
  },
  PROCESSING: {
    icon: 'clock',
    color: '#FBB23C',
    text: __('To due')
  },
  FAILED: {
    icon: 'times',
    color: '#ED335F',
    text: __('Refused')
  },
  CANCELED: {
    icon: 'hand holding usd',
    color: '#0080FF',
    text: __('Refunded')
  },
  PAID_MANUALLY: {
    icon: 'hand holding usd',
    color: '#37b24d',
    text: __('Confirmed')
  },
  OVERDUE: {
    icon: 'clock',
    color: '#ED335F',
    text: __('Overdue')
  },
  EXPIRED: {
    icon: 'circle xmark',
    color: '#00000099',
    text: __('Expired (fem)')
  }
};

@inject('store', 'client')
@graphql(gql`query ChargePaymentsQuery (
  $organizationId: ID!,
  $limit: Int,
  $offset: Int,
  $search: String,
  $initialDate: String,
  $finalDate: String,
  $method: [PaymentMethodEnum],
  $status: [ChargePaymentStatus]
  ) {
  node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
    ... on Organization {
      id: dbId
      currency
      timezone
      financialInformations  (search: $search, status: $status, methods: $method, dateLimitInterval: { initialDate: $initialDate, finalDate: $finalDate }){
        amountReceived {
          amount
          quantity
        }
        amountToReceive {
          amount
          quantity
        }
        amountOverdued {
          amount
          quantity
        }
      }
      chargeRecipients (limit: $limit, offset: $offset, search: $search, methods: $method, status: $status, dateLimitInterval: { initialDate: $initialDate, finalDate: $finalDate }) {
        totalCount
        nodes {
          id: dbId
          status
          method
          currentAmount
          charge {
            id: dbId
            name
            amount
            paymentMethods
            dateLimit
            boletoFee
            cardFee
            payableExpired
          }
          entity {
            id: dbId
            fullname
            picture {
              uri (size: "s70")
              id: dbId
              key
            }
          }
          payments {
            nodes {
              id: dbId
              status
              created
              lastStatusUpdate
              paidDate
              method
              link
              paidAmount
              creditDate
              externalDetails {
                isExternalSource
                amountToPay
                updatedPaidAmount
                amountToReceive
                installments
                updatedStatus
              }
              user {
                id: dbId
                fullname
              }
            }
          }
        }
      }
    }
  }
}
  `, {
  options: ownProps => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      organizationId: ownProps.params.organization_id,
      limit: parseInt(ownProps.location.query.limit || PAGE_SIZE, 10),
      offset: parseInt(((ownProps.location.query.p || 1) - 1) * (ownProps.location.query.limit || PAGE_SIZE), 10),
      search: ownProps.location.query.search || '',
      initialDate: ownProps.location.query.startDate ? moment(ownProps.location.query.startDate).format('YYYY-MM-DD HH:mm:ss') : moment(defaultInterval.startDate).format('YYYY-MM-DD HH:mm:ss'),
      finalDate: ownProps.location.query.endDate ? moment(ownProps.location.query.endDate).format('YYYY-MM-DD HH:mm:ss') : moment(defaultInterval.endDate).format('YYYY-MM-DD HH:mm:ss'),
      method: ownProps.location.query.methods || undefined,
      status: ownProps.location.query.status || undefined
    }
  })
})
@observer
export default class ChargePayments extends Page {
  constructor(props) {
    super(props);

    const { startDate, endDate, filter, custom, search } = props.location.query;

    const diffBetweenDates = moment(endDate).diff(startDate, 'months');
    const invalidParametersDetected = diffBetweenDates > 2 || diffBetweenDates < 0;

    this.state = {
      loading: false,
      closedModal: true,
      startDate: !invalidParametersDetected && startDate ? startDate : defaultInterval.startDate,
      endDate: !invalidParametersDetected && endDate ? endDate : defaultInterval.endDate,
      custom: custom || false,
      filter: filter || 'month',
      selectDateSwitch: false,
      search,
      columns: [
        { value: 'status', text: __('Status'), visible: true, size: '6fr' },
        { value: 'dueDate', text: __('Due date'), visible: true, size: '7fr' },
        { value: 'approvedOn', text: __('Approved on'), visible: true, size: '7fr' },
        { value: 'title', text: __('Title'), visible: true, size: '9fr' },
        { value: 'amount', text: __('Value'), visible: true, size: '6fr' },
        { value: 'method', text: __('Method'), visible: true, size: '7fr' },
        { value: 'entity', text: __('Entity'), visible: true, size: '10fr' }
      ],
      methodsFilter: [
        { text: __('Boleto'), value: 'BOLETO', checked: props.location.query.methods && props.location.query.methods.includes('BOLETO') },
        { text: __('Card'), value: 'CARD', checked: props.location.query.methods && props.location.query.methods.includes('CARD') },
        { text: __('Pix'), value: 'PIX', checked: props.location.query.methods && props.location.query.methods.includes('PIX') },
        { text: __('Manual'), value: 'EXTERNAL', checked: props.location.query.methods && props.location.query.methods.includes('EXTERNAL') }
      ],
      statusFilter: [
        { text: __('Paid'), value: ['PAID'], checked: props.location.query.status && props.location.query.status.includes('PAID') },
        { text: __('To due'), value: ['PENDING', 'PROCESSING'], checked: props.location.query.status && (props.location.query.status.includes('PENDING') || props.location.query.status.includes('PROCESSING')) },
        { text: __('Overdue'), value: ['OVERDUE'], checked: props.location.query.status && props.location.query.status.includes('OVERDUE') },
        { text: __('Expired (fem)'), value: ['EXPIRED'], checked: props.location.query.status && props.location.query.status.includes('EXPIRED') },
        { text: __('Refunded'), value: ['CANCELED'], checked: props.location.query.status && props.location.query.status.includes('CANCELED') },
      ],
      screenWidth: window.innerWidth,
      useAsyncExport : null
    };

    if (invalidParametersDetected) {
      this.onApplyFilters();
    }
  }

  componentDidUpdate(prevProps) {
    const { reloadTab } = this.props;
    const { methodsFilter, statusFilter, useAsyncExport } = this.state;
    const { startDate, endDate } = defaultInterval;

    if (reloadTab !== prevProps.reloadTab) {
      this.setState({
        startDate,
        endDate,
        custom: false,
        filter: 'month',
        methodsFilter: methodsFilter.map(m => ({ ...m, checked: false })),
        statusFilter: statusFilter.map(s => ({ ...s, checked: false })),
      });
    }
    if(useAsyncExport == null){
      this.shouldExportAsync()
    }
  }

  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
  }

  shouldExportAsync(){
    const { currentOrganization, app } = this.props.store;
    UnleashAxios.create(app.env).isEnabled(
      'classpay_async_export',
      { organizationId: `${currentOrganization && currentOrganization.id}` }
    ).then((result) => {
      this.setState({ useAsyncExport: result });
    }).catch((err) => {
      console.error(err);
      this.setState({ useAsyncExport: false });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth = () => {
    this.setState({ screenWidth: window.innerWidth });
  }

  request = (paymentId) => {
    this.setState({ loading: true });
    return this.props.mutate({
      variables: {
        deleteChargePayment: {
          id: paymentId,
        }
      }
    }).then(() => {
      this.props.store.snackbar = { active: true, message: __('Charge was refunded'), success: true };
      this.setState({ loading: false, closedModal: true });
      this.props.store.appends.pop();
    }).catch((err) => {
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
      this.setState({ loading: false, closedModal: true });
    });
  }

  handleDropdownClick = ({ value }) => {
    let startDate;
    let endDate;
    let filter = value;

    if (value === 'week') {
      filter = 'week';
      endDate = moment().startOf('week').subtract(1, 'days').format();
      startDate = moment().subtract(1, 'weeks').startOf('week').format();
    } else if (value === 'month') {
      filter = 'month';
      endDate = moment().endOf('month').format();
      startDate = moment().startOf('month').format();
    } else if (value === 'last-month') {
      filter = 'last-month';
      endDate = moment().startOf('month').subtract(1, 'days').format();
      startDate = moment().subtract(1, 'month').startOf('month').format();
    } else {
      startDate = moment().subtract(value, 'days').format();
      endDate = moment().format();
    }

    startDate = moment(startDate).format('YYYY-MM-DDT00:00:00.000Z');
    endDate = moment(endDate).format('YYYY-MM-DDT23:59:59.000Z');

    this.setState({ filter, page: 1, startDate, endDate, custom: false }, () => {
      this.dropdown.close();
      this.onApplyFilters();
    });
  }

  handleCalendarClick = (start, end) => {
    const startDate = start;
    let endDate = end;

    if (endDate && startDate) {
      const diffBetweenDates = moment(endDate).diff(startDate, 'months');
      if (diffBetweenDates >= 2 || diffBetweenDates < 0) {
        endDate = (moment(startDate).add(2, 'month')).subtract(1, 'days').format('YYYY-MM-DD');
      }

      this.setState({
        page: 1,
        startDate: moment(startDate).format('YYYY-MM-DDT00:00:00.000Z'),
        endDate: moment(endDate).format('YYYY-MM-DDT23:59:59.000Z'),
        custom: true
      });
    }
  }

  renderColumns = () => {
    const columns = [];

    if (this.state.columns.find(column => column.value === 'status').visible) columns.push(__('Status'));
    if (this.state.columns.find(column => column.value === 'dueDate').visible) columns.push(__('Due date'));
    if (this.state.columns.find(column => column.value === 'approvedOn').visible) {
      columns.push(
        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          {__('Approved on')}
          <Popup
            hoverable
            style={{ marginLeft: '-14px' }}
            trigger={<Icon name="question circle outline" size="small" style={{ opacity: 0.6, marginLeft: '5px' }} />}
            content={(
              <div>
                <p>{__('This field represents the system approval in the following status: paid and refunded.')}</p>
                <p>{__('Paid Status: the “approved at” informs the date when the partner company approved the payment, for more information about the reconciliation, click ')}<a target="_blank" href={'https://ajuda.classapp.com.br/hc/pt-br/articles/1260803084710'}>{__('here')}.</a></p>
                <p>{__('Refunded Status: the “approved on” informs the date when a partner company has approved the refund.')}</p>
              </div>
            )}
          />
        </div>
      );
    }
    if (this.state.columns.find(column => column.value === 'title').visible) columns.push(__('Title'));
    if (this.state.columns.find(column => column.value === 'amount').visible) columns.push(__('Value'));
    if (this.state.columns.find(column => column.value === 'method').visible) columns.push(__('Method'));
    if (this.state.columns.find(column => column.value === 'entity').visible) columns.push(__('Entity'));

    columns.push(null);

    return columns;
  }

  onApplyFilters = () => {
    const { startDate, endDate, methodsFilter, statusFilter, custom, filter } = this.state;

    const methods = methodsFilter.filter(item => item.checked).map(item => item.value);
    const status = _.flatten(statusFilter.filter(item => item.checked).map(currentStatus => currentStatus.value));

    this.onParameterChange({
      startDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss') || null,
      endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss') || null,
      methods: methods.length > 0 ? methods : null,
      status: status.length > 0 ? status : null,
      filter,
      custom
    });
  }

  onParameterChange = (data) => {
    const parameters = Object.keys(data).map(name => ({ name, value: data[name] }));
    this.onMultipleParametersChange(parameters);
  }

  renderEntityFullname = ({ to, fullname }) => {
    if (this.props.store.currentEntity.type !== 'ADMIN') return <span>{fullname}</span>;

    return <Link className="bold defaultClspColor" to={to}>{fullname}</Link>;
  }

  renderEntity = (entity, payment) => (
    <Header as="h5" className="entityItem" title={entity.fullname} data-id={entity.id}>
      <Avatar style={{ width: '35px', height: '35px' }} avatar spaced="right" src={entity.picture && entity.picture.uri} alt={entity.fullname || ''} />
      <Header.Content>
        {
          entity.fullname ?
            (
              entity.fullname.length > 18 ?
                <Popup
                  trigger={this.renderEntityFullname({ to: `/entities/${entity.id}`, fullname: entity.fullname.substring(0, 14).trim() + '...' })}
                  content={entity.fullname}
                />
                :
                this.renderEntityFullname({ to: `/entities/${entity.id}`, fullname: utils.renderLongText(entity.fullname, 12) })
            )
            :
            null
        }
        {payment && payment.user && payment.user.fullname && (
          <Header.Subheader>
            {
              payment.user.fullname.length > 18 ?
                <Popup
                  trigger={<span>{payment.user.fullname.substring(0, 14).trim()} ...</span>}
                  content={payment.user.fullname}
                />
                :
                (utils.renderLongText(payment.user.fullname, 18))
            }
          </Header.Subheader>
        )}
      </Header.Content>
    </Header>
  )

  renderRowCells = (recipient) => {
    const { id, method, link, charge, entity, status, payments, currentAmount } = recipient;
    const { store } = this.props;
    const { currency: orgCurrency } = this.props.data.node;

    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    const columns = [];
    let currentStatus;
    if (status === 'EXPIRED' && charge.payableExpired) currentStatus = statusMap.OVERDUE;
    else currentStatus = statusMap[status];
    const payment = payments.nodes[0];

    if (this.state.columns.find(column => column.value === 'status').visible) {
      columns.push({
        value: (
          <div style={{ display: 'flex', color: currentStatus.color }}>
            <Icon name={currentStatus.icon} style={{ fontSize: '12px' }} />
            {currentStatus.text}
          </div>
        )
      });
    }

    if (this.state.columns.find(column => column.value === 'dueDate').visible) {
      columns.push({ value: <div style={status === 'CANCELED' ? { textDecorationLine: 'line-through' } : null}>{utils.simpleDate(charge.dateLimit, true, 'L', lang)}</div> });
    }

    if (this.state.columns.find(column => column.value === 'approvedOn').visible) {
      let approvedDateOn = '-';
      if (status === 'PAID' && payment) approvedDateOn = utils.simpleDate(payment.paidDate, true, 'L', lang);
      else if (status === 'CANCELED' && payment) approvedDateOn = utils.simpleDate(payment.lastStatusUpdate, true, 'L', lang);
      columns.push({ value: <div style={approvedDateOn === '-' ? { textAlign: 'center' } : null}>{approvedDateOn}</div> });
    }

    if (this.state.columns.find(column => column.value === 'title').visible) {
      if (status === 'CANCELED' && payment) columns.push({ value: <div style={{ textDecorationLine: 'line-through' }}>{utils.renderLongText(charge.name, 16)}</div> });
      else columns.push({ value: utils.renderLongText(charge.name, 16) });
    }

    if (this.state.columns.find(column => column.value === 'amount').visible) {
      columns.push({
        value: (
          <div style={status === 'CANCELED' ? { textDecorationLine: 'line-through' } : null}>
            {(currentAmount).toLocaleString('pt-BR', { style: 'currency', currency: orgCurrency || 'BRL' })}
          </div>
        )
      });
    }

    if (this.state.columns.find(column => column.value === 'method').visible) {
      if (method) columns.push({ value: paymentMethods()[method] });
      else columns.push({ value: <div style={{ textAlign: 'center' }}>-</div> });
    }

    if (this.state.columns.find(column => column.value === 'entity').visible) columns.push({ value: this.renderEntity(entity, payment) });

    const button = (
      <Button
        basic
        icon="eye"
        onClick={() => store.appends.push(<PaymentInformation chargeId={charge.id} entityId={entity.id} timezone={this.props.data.node.timezone} recipient={recipient} />)}
      />
    );

    columns.push({
      value: (
        this.isMobile() ? (button) : <Popup
          trigger={button}
          content={__('See details')}
        />
      )
    });

    return columns;
  }

  renderFilter = () => {
    const { data } = this.props;
    const { filter, startDate: selectedStartDate, endDate: selectedEndDate, methodsFilter, statusFilter, custom } = this.state;

    let startDate = new Date(moment().subtract(30, 'days'));
    let endDate = new Date(moment());
    let selectedLimit = { text: __('Last %s days', 30) };

    if (!custom && filter === 'week') {
      const endOfLastWeek = moment().startOf('week').subtract(1, 'days');
      const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week');

      endDate = new Date(endOfLastWeek);
      startDate = new Date(startOfLastWeek);
      selectedLimit = limitOptions.find(option => option.value === 'week');
    } else if (!custom && filter === 'month') {
      const endOfLastMonth = moment().endOf('month');
      const startOfLastMonth = moment().startOf('month');

      endDate = new Date(endOfLastMonth);
      startDate = new Date(startOfLastMonth);
      selectedLimit = limitOptions.find(option => option.value === 'month');
    } else if (!custom && filter === 'last-month') {
      const endOfLastMonth = moment().startOf('month').subtract(1, 'days');
      const startOfLastMonth = moment().subtract(1, 'month').startOf('month');

      endDate = new Date(endOfLastMonth);
      startDate = new Date(startOfLastMonth);
      selectedLimit = limitOptions.find(option => option.value === 'last-month');
    } else {
      endDate = selectedEndDate ? new Date(selectedEndDate) : new Date();
      startDate = selectedStartDate ? new Date(selectedStartDate) : new Date(moment().startOf('month'));

      if (custom) {
        selectedLimit = {
          text: __('%s to %s', `${startDate.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 })}/${months[startDate.getMonth()]}`, `${endDate.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 })}/${months[endDate.getMonth()]}`),
        };
      } else {
        selectedLimit = limitOptions[limitOptions.findIndex(option => option.value === filter)] || { text: __('This month') };
      }
    }

    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);

    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

    const methods = methodsFilter.filter(item => item.checked).map(item => item.text);
    const status = _.flatten(statusFilter.filter(item => item.checked).map(item => item.text));

    return (
      <Container id="PaymentsDashboard" key={0} style={{ display: 'flex', width: '100%!important', justifyContent: this.isMobile() ? 'center' : 'space-between', margin: '10px 0px' }}>
        {this.isMobile() ? (
          <div style={{ width: '100%', margin: '0px -10px' }}>
            <div style={{ margin: '10px 0px' }}>
              <Icon name="filter" />
              <strong>{__('Filter by:')}</strong>
            </div>
            {data.loading ? <div style={{ width: '100%', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader active inline="centered" /></div> : (
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                  <div style={{ width: '50%' }}>
                    <strong style={{ marginLeft: '5px' }}>{__('Method')}</strong>
                    {!data.loading &&
                      <Dropdown className={'dropdownFilter'} style={{ width: '100%' }} basic inline text={utils.renderLongText(!data.loading && methods.length > 0 ? methods.join(', ') : __('No filter'), 10)} loading={data.loading}>
                        <Dropdown.Menu >
                          {methodsFilter.map(method => (
                            <Dropdown.Item
                              text={method.text}
                              onClick={() => {
                                method.checked = !method.checked;
                                this.setState({ methodsFilter });
                              }}
                            >
                              <ColoredCheckbox
                                label={method.text}
                                checked={method.checked}
                              />
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    }
                  </div>
                  <div style={{ marginLeft: '5px', width: '50%' }}>
                    <strong style={{ marginLeft: '5px', marginTop: '10px' }}>{__('Status')}</strong>
                    {!data.loading &&
                      <Dropdown className={'dropdownFilter'} style={{ width: '100%' }} basic inline text={utils.renderLongText(!data.loading && status.length > 0 ? status.join(', ') : __('No filter'), 10)} loading={data.loading}>
                        <Dropdown.Menu>
                          {statusFilter.map(currentStatus => (
                            <Dropdown.Item
                              text={currentStatus.text}
                              onClick={() => {
                                currentStatus.checked = !currentStatus.checked;
                                this.setState({ statusFilter });
                              }}
                            >
                              <ColoredCheckbox
                                label={currentStatus.text}
                                checked={currentStatus.checked}
                              />
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    }
                  </div>

                </div>
                <div>
                  <div
                    onClick={() => this.props.store.appends.push(<BetaModal
                      toast
                      id={'PaymentFilterInterval'}
                      header={<div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                        {__('Interval')}
                        <a
                          style={{ position: 'absolute', right: 10 }}
                          onClick={() => {
                            this.props.store.appends.pop();
                            this.onApplyFilters();
                          }}
                        >{__('Apply')}</a>
                      </div>}
                      closeOnRootNodeClick
                      invertCloseButton
                      scrolling
                      onClose={() => this.props.store.appends.pop()}
                      content={
                        <div>
                          <CalendarInterval
                            maxIntervalInMonths={2}
                            startDate={startDate}
                            minStartDate={moment('2020-01-01')}
                            onUpdateStartDate={(dateSelected) => {
                              this.setState({
                                page: 1,
                                startDate: moment(dateSelected.selectedDt).format('YYYY-MM-DDT00:00:00.000Z'),
                                endDate: moment(dateSelected.selectedDt).add(2, 'month').subtract(1, 'days').format('YYYY-MM-DDT23:59:59.000Z'),
                                custom: true
                              });
                            }}
                            endDate={endDate}
                            minEndDate={startDate}
                            maxEndDate={new Date((moment(startDate).add(2, 'month')).subtract(1, 'days'))}
                            onUpdateEndDate={(dateSelected) => {
                              this.setState({
                                endDate: moment(dateSelected.selectedDt).format('YYYY-MM-DDT23:59:59.000Z'),
                                custom: true
                              });
                            }}
                          />

                        </div>
                      }
                    />)}
                  >
                    <strong style={{ marginTop: '20px' }}>{__('Period')}</strong>
                    <div className={'dropdownFilter'} style={{ width: '100%', justifyContent: 'center' }}>
                      {!data.loading && __('%s to %s', `${startDate.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 })}/${months[startDate.getMonth()]}`, `${endDate.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 })}/${months[endDate.getMonth()]}`)}
                      <Icon size="small" name="dropdown" style={{ fontSize: '.85714286em' }} />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div style={{ marginTop: '15px' }}>
              {!data.loading && <BetaButton
                data-action="submit"
                round
                icon={{ name: 'search' }}
                text={__('Apply')}
                style={{ width: '100%' }}
                onClick={() => this.onApplyFilters()}
              />
              }
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="filter" />
            <strong>{__('Filter by:')}</strong>
            {!data.loading && <strong style={{ marginLeft: '5px' }}>{__('Period')}</strong>}
            <span style={{ color: '#4a90e2', marginLeft: '10px' }}>
              <Dropdown ref={(c) => { this.dropdown = c; }} inline data-action={'open-more-settings'} basic text={!data.loading && selectedLimit.text} loading={data.loading}>
                <Dropdown.Menu onClick={e => e.stopPropagation()}>
                  <div style={{ display: 'flex', color: 'black', backgroundColor: 'white', padding: '4px 0px', borderRadius: '4px', boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)', border: 'solid 1px #e9ecef' }}>
                    <div style={{ marginBottom: 'auto', marginTop: 'auto' }}>
                      {
                        limitOptions.map((option, i) =>
                          <Dropdown.Item
                            key={i}
                            {...option}
                            style={{ ...styles.dropdownItem, backgroundColor: option === selectedLimit ? 'rgba(0, 118, 255, 0.07)' : 'white' }}
                            active={option === selectedLimit}
                            onClick={() => this.handleDropdownClick(option)}
                          />
                        )
                      }
                    </div>
                    <div style={{ padding: '5px 16px', borderLeft: 'solid 1px #e9ecef' }}>
                      <p style={{ marginBottom: '8px', marginTop: '4px', fontSize: '1.1em', fontWeight: 'bold' }}>{__('Period')}
                        <Popup
                          hoverable
                          style={{ marginLeft: '-14px' }}
                          trigger={<Icon name="question circle outline" size="medium" style={{ opacity: 0.6, marginLeft: '5px' }} />}
                          content={__('When applying a period in this filter, all charges that have been approved and/or that have a overdue date corresponding to the determined period will be listed.')}
                        />
                      </p>
                      <p style={{ marginBottom: '8px', marginTop: '4px', color: 'gray' }}>{__('The maximum interval that can be selected/filtered is 60 days.')}</p>
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '10px' }}>
                          <p style={{ marginBottom: '-0.4em' }}>{__('Begin')}</p>
                          <Calendar
                            withBorder
                            withPadding
                            calendarStyles
                            headerAlwaysOn
                            selectedDt={startDate}
                            minDate={moment('2020-01-01')}
                            onSelect={(dateSelected) => {
                              this.setState({
                                page: 1,
                                startDate: moment(dateSelected.selectedDt).format('YYYY-MM-DDT00:00:00.000Z'),
                                endDate: moment(dateSelected.selectedDt).add(2, 'month').subtract(1, 'days').format('YYYY-MM-DDT23:59:59.000Z'),
                                custom: true
                              });
                            }}
                          />
                        </div>
                        <div>
                          <p style={{ marginBottom: '-0.4em' }}>{__('End')}</p>
                          <Calendar
                            withBorder
                            withPadding
                            calendarStyles
                            headerAlwaysOn
                            selectedDt={endDate}
                            minDate={startDate}
                            maxDate={new Date((moment(startDate).add(2, 'month')).subtract(1, 'days'))}
                            onSelect={(dateSelected) => {
                              this.setState({
                                endDate: moment(dateSelected.selectedDt).format('YYYY-MM-DDT23:59:59.000Z'),
                                custom: true
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </span>

            {!data.loading && <strong style={{ marginLeft: '5px' }}>{__('Method')}</strong>}
            {!data.loading && <span style={{ color: '#4a90e2', marginLeft: '10px' }}>
              <Dropdown basic inline text={utils.renderLongText(!data.loading && methods.length > 0 ? methods.join(', ') : __('No filter'), 25)} loading={data.loading}>
                <Dropdown.Menu >
                  {methodsFilter.map(method => (
                    <Dropdown.Item
                      text={method.text}
                      onClick={() => {
                        method.checked = !method.checked;
                        this.setState({ methodsFilter });
                      }}
                    >
                      <ColoredCheckbox
                        label={method.text}
                        checked={method.checked}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </span>
            }
            {!data.loading && <strong style={{ marginLeft: '5px' }}>{__('Status')}</strong>}
            {!data.loading && <span style={{ color: '#4a90e2', marginLeft: '10px' }}>
              <Dropdown basic inline text={utils.renderLongText(!data.loading && status.length > 0 ? status.join(', ') : __('No filter'), 25)} loading={data.loading}>
                <Dropdown.Menu>
                  {statusFilter.map(currentStatus => (
                    <Dropdown.Item
                      text={currentStatus.text}
                      onClick={() => {
                        currentStatus.checked = !currentStatus.checked;
                        this.setState({ statusFilter });
                      }}
                    >
                      <ColoredCheckbox
                        label={currentStatus.text}
                        checked={currentStatus.checked}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </span>
            }
          </div>
        )}

        {!data.loading && !this.isMobile() &&
          (
            <BetaButton
              data-action="submit"
              round
              icon={{ name: 'search' }}
              text={__('Apply')}
              onClick={() => this.onApplyFilters()}
            />
          )}

      </Container>
    );
  }

  getExportLink = () => {
    const { store, location } = this.props;
    const { query } = location;
    const { search, startDate, endDate } = query;
    let { methods, status } = query;

    let paramsValidated = '';
    if (search) paramsValidated += `&search=${search}`;
    if (status && typeof status === 'string') status = [status];
    if (status && status.length > 0) paramsValidated += `&status=${status.join(',')}`;
    if (methods && typeof methods === 'string') methods = [methods];
    if (methods && methods.length > 0) paramsValidated += `&methods=${methods.join(',')}`;
    const lang = store.app.locale ? (store.app.locale === 'pt-BR' ? 'pt' : store.app.locale) : 'en';
    const exportLink = `${store.app.url}/csv/organization/payments?client_id=${client_id}&` +
      `limit=${10000}&offset=${query.p || 0}&initialDate=${startDate || defaultInterval.startDate}&finalDate=${endDate || defaultInterval.endDate}${paramsValidated}&organization_id=${store.currentOrganization.id}&access_token=${encodeURIComponent(store.access_token)}&tz_offset=${-(new Date().getTimezoneOffset())}&locale=${lang}`;
    return exportLink;
  }

  renderTableFilter = () => {
    const { columns, loading, useAsyncExport } = this.state;
    const {
      search, startDate, endDate, methods, status
    } = this.props.location.query;
    const { currentOrganization, app } = this.props.store;
    const exportLink = this.getExportLink();
    const filters = useAsyncExport && {
      organizationId: currentOrganization.id,
      search: search,
      status: typeof status === 'string' ? [status] : status,
      methods: typeof methods === 'string' ? [methods] : methods,
      initialDate: startDate || defaultInterval.startDate,
      finalDate: endDate || defaultInterval.endDate,
      locale: app.locale ? (app.locale === 'pt-BR' ? 'pt' : app.locale) : 'en',
      tzOffset: -(new Date().getTimezoneOffset())
    };

    return (
      <div>
        <TableActionBar
          style={{ alignItems: 'center', width: '100%' }}
          customFilter={this.isMobile() ? null : (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
              <Dropdown
                className={'dropdownFilter'}
                text={__('Columns')}
                data-params="columns"
                calendarStyles
                item
              >
                <Dropdown.Menu>
                  {columns.map((column, i) => <Dropdown.Item
                    style={{ display: 'flex' }}
                    onClick={() => {
                      columns[i].visible = !column.visible;
                      this.setState({ columns });
                    }}
                  >
                    <ColoredCheckbox
                      data-value={column.value}
                      checked={column.visible}
                      style={{ marginRight: '10px' }}
                    />
                    {column.text}
                  </Dropdown.Item>)}
                </Dropdown.Menu>
              </Dropdown>
              <Button
                style={{
                  position: 'absolute',
                  right: '7rem',
                  height: 40,
                  color: '#495057',
                  backgroundColor: '#fff',
                  border: '1px solid #e9ecef',
                  borderRadius: 4
                }}
                as="a"
                href={!useAsyncExport && exportLink}
                onClick={useAsyncExport && (() => this.props.store.appends.push(
                  <ExportModal
                    onConfirm={async (email) => {
                      await createOrganizationPaymentsReportUseCase().execute(
                        this.props.client,
                        (active, message, success, dismissAfter) => {
                          this.props.store.snackbar = {
                            active: active,
                            message: message,
                            success: success,
                            dismissAfter: dismissAfter
                          }
                        },
                        { email, ...filters }
                      );
                      this.props.store.appends.pop();
                    }}
                    onClose={() => this.props.store.appends.pop()}
                  />
                ))}
              >
                <Icon name="download" />
                {__('Export')}
              </Button>
            </div>
          )}
          search={{ placeholder: __('Search...'), fn: value => this.onUniqueParameterChange('search', value), fillAllWidth: this.isMobile(), style: { marginBottom: '10px' } }}
          loading={loading}
        />

      </div>
    );
  }

  renderMainInfoItem = (title, value, subvalue, info) => {
    const { currency: orgCurrency } = this.props.data.node;
    return (
      <div style={{ flex: 1, height: '100%', padding: '20px 5px', margin: '0px -10px', border: '0.4px solid #E0E0E0', borderRadius: '6px' }}>
        <div style={{ position: 'absolute', top: this.isMobile() ? '15%' : '5%', right: 10 }}>
          <Popup
            hoverable
            style={{ marginLeft: '-14px' }}
            trigger={<Icon name="question circle outline" size="medium" style={{ opacity: 0.6, marginLeft: '5px' }} />}
            content={info}
          />
        </div>
        <div style={{ textAlign: 'center', fontSize: 13, fontWeight: 'bold', marginBottom: '10px', coxlor: '#666666' }}>
          {title.text.toUpperCase()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
          <Card.Description style={{ marginLeft: 4, fontSize: 24 }}>{`${(value || 0).toLocaleString('pt-BR', { style: 'currency', currency: orgCurrency || 'BRL' })}`}</Card.Description>
          <Card.Description style={{ marginLeft: 4, fontSize: 14, marginTop: 10, color: '#666666' }}>{subvalue}</Card.Description>
        </div>
      </div>
    );
  }

  renderMainInfo = () => {
    const { data } = this.props;

    const { financialInformations } = data.node;
    const { amountToReceive, amountReceived, amountOverdued } = financialInformations;

    const totalToReceiveAndOverdue = (amountOverdued.quantity + amountToReceive.quantity) > 1000 ? '+999' : amountOverdued.quantity + amountToReceive.quantity;
    const totalReceived = amountReceived.quantity > 1000 ? '+999' : amountReceived.quantity;
    const totalOverdued = amountOverdued.quantity > 1000 ? '+999' : amountOverdued.quantity;
    const totalToReceive = amountToReceive.quantity > 1000 ? '+999' : amountToReceive.quantity;

    return (
      <div style={{ marginBottom: 24 }}>
        <Grid stackable>
          <Grid.Row columns={4}>
            <Grid.Column>{
              this.renderMainInfoItem(
                { text: __('Received') },
                amountReceived.amount,
                totalReceived === 1 ? __('1 charge paid') : __('%s charges paid', totalReceived),
                (
                  <div>
                    <p>
                      <b>{__('Amount received')}</b>{' '}
                      {__('is the sum of all amounts paid via ClassPay or Manual Write-Off. After the intermediary Pagar.me recognizes the payment, the Pix, is available for withdrawal the same day, the boleto within 5 business days and the credit card within 29 + 2 business days. Manual Write-off amounts are not part of the balance available for withdrawal. Understand more about the settlement of payments by clicking')}{' '}
                      <a target="_blank" href="https://ajuda.classapp.com.br/hc/pt-br/articles/1260803112669-Como-funciona-a-confirma%C3%A7%C3%A3o-de-pagamentos-de-um-boleto-">{__('here')}.</a>
                    </p>
                  </div>
                )
              )}
            </Grid.Column>

            <Grid.Column>{
              this.renderMainInfoItem(
                { text: __('To receive') },
                amountOverdued.amount + amountToReceive.amount,
                totalToReceiveAndOverdue === 1 ? __('1 charge to overdue/ overdued') : __('%s charges to overdue/ overdued', totalToReceiveAndOverdue),
                (
                  <div>
                    <p><b>{__('Amount to be received')}</b> {__('is the sum of the amounts of charges with Overdue and Due status')}</p>
                  </div>
                )
              )}
            </Grid.Column>

            <Grid.Column>{
              this.renderMainInfoItem(
                { text: __('Overdued') },
                amountOverdued.amount,
                totalOverdued === 1 ? __('1 charge overdued') : __('%s charges overdued', totalOverdued),
                (
                  <div>
                    <p><b>{__('Overdue Amount')}</b> {__('is the sum of the amounts of charges with status Overdue.')}</p>
                    <p>{__('The amounts are already updated with interest, fine and/or fee, if any.')}</p>
                    <p>{__('The charges have already been sent to the customer and are overdue. If the customer has presented proof of payment, the Pagar.me processing period must wait for the automatic write-off to occur.')}</p>
                    <p>{__('Learn more about payment reconciliation by clicking')} <a target="_blank" href="https://ajuda.classapp.com.br/hc/pt-br/articles/1260803084710-Liquida%C3%A7%C3%A3o-de-P[…]-leva-para-os-valores-ficarem-dispon%C3%ADveis-para-saque-">{__('here')}.</a></p>
                  </div>
                )
              )}
            </Grid.Column>

            <Grid.Column>{
              this.renderMainInfoItem(
                { text: __('To due') },
                amountToReceive.amount,
                totalToReceive === 1 ? __('1 charge to overdue') : __('%s charges to overdue', totalToReceive),
                (
                  <div>
                    <p><b>{__('Amount Due')}</b> {__('is the sum of the amounts of charges with status Due.')}</p>
                    <p>{__('The displayed values ​​may have discounts applied, if any.')}</p>
                    <p>{__('The charges have already been sent to the customer, but the payment has not yet been made. If the customer has presented proof of payment, the Pagar.me processing period must wait for the automatic write-off to occur.')}</p>
                    <p>{__('Learn more about payment reconciliation by clicking')} <a target="_blank" href="https://ajuda.classapp.com.br/hc/pt-br/articles/1260803084710-Liquida%C3%A7%C3%A3o-de-P[…]-leva-para-os-valores-ficarem-dispon%C3%ADveis-para-saque- ">{__('here')}.</a></p>
                  </div>
                )
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  render() {
    const { data, location } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    const { nodes, totalCount } = data.node.chargeRecipients;

    return (
      <div id="ChargePayments" style={{ marginTop: 32, marginLeft: 10 }}>
        <div>
          <div style={{ flexDirection: 'column' }}>
            {this.renderFilter()}
            {this.renderMainInfo()}
            {this.renderTableFilter()}
          </div>

        </div>
        {data.loading ? (
          <Loader active inline="centered" />
        ) : (
          <div>
            <div style={{ ...(this.isMobile() && nodes.length > 0 ? { overflowX: 'scroll' } : null) }}>
              {nodes.length === 0 ? (
                <div style={{ margin: '50px 0px' }}>
                  <EmptyImage
                    url="/images/pablita-588.png"
                    title={<div style={{ textAlign: 'center' }}>{__('No payments found !')}</div>}
                  />
                </div>
              ) : <Table
                colNames={this.renderColumns()}
                style={{ display: 'grid', width: '100%', minWidth: '970px' }}
                data={nodes}
                rows={nodes.map(item => this.renderRowCells(item))}
                loading={data.loading}
                location={location}
                gridColumns={this.state.columns.map(item => (item.visible ? item.size : '')).join(' ') + ' 2fr'}
                gridGap={'0px'}
              />
              }
            </div>
            {nodes.length > 0 && (
              <Paginator
                isMobile={this.isMobile}
                source={nodes}
                pagination={{
                  loading: data.loading,
                  totalCount,
                  query: location.query,
                  noAllOption: true
                }}
                onLoadMore={this.eventReload}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
