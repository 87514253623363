import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`mutation deleteCharge($deleteChargeMutation: DeleteChargeInput!) {
  deleteCharge(input: $deleteChargeMutation) {
    charge {
      dbId
      name
    }
  }
}`, {
  options: {
  refetchQueries: ['MessageNodeOrganizationQuery', 'MessageNodeEntityQuery']
  }
  })
@observer
export default class ChargeDelete extends Controller {
  request = () => this.props.mutate({
    variables: {
      deleteChargeMutation: {
        id: this.props.charge.id,
        name: this.props.charge.name
      }
    }
  }).then(() => {
    this.props.store.snackbar = { active: true, message: __('Charge was deleted'), success: true };
    this.props.store.appends.pop();
  })

  render() {
    return (
      <Controller
        id="ChargeDelete"
        delete
        object={__('charge')}
        onSubmit={this.onSubmit}
        onCancel={() => this.props.store.appends.pop()}
        {...this.props}
      />
    );
  }
}
