import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';

import * as utils from '../../utils';
import { __ } from '../../i18n';

@inject('store')
@graphql(gql`mutation deleteChargePayment($deleteChargePayment: DeleteChargePaymentInput!) {
    deleteChargePayment(input: $deleteChargePayment) {
      chargePayment {
        id: dbId
      }
    }
  }`, {
  options: {
  refetchQueries: ['PaymentDetailsQuery', 'ChargePaymentsQuery']
  }
  })
@observer
export default class ChargePaymentDelete extends Controller {
    request = () => this.props.mutate({
      variables: {
        deleteChargePayment: {
          id: this.props.payment.id,
        }
      }
    }).then(() => {
      this.props.store.snackbar = { active: true, message: __('Charge was refunded'), success: true };
      this.props.store.appends.pop();
      if (this.props.onClose) this.props.onClose();
    }).catch((err) => {
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    })

    render() {
      return (
        <Controller
          id="ChargePaymentDelete"
          delete
          submitButton={__('Confirm')}
          title={__('Refund payment')}
          content={__('Are you sure you want to refund this payment?')}
          onSubmit={this.onSubmit}
          onCancel={() => this.props.store.appends.pop()}
          loading={this.state.loading}
          disabled={this.state.disabled || this.state.loading}
          {...this.props}
        />
      );
    }
}
