import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Table, Icon, Message } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../../i18n';
import * as utils from '../../utils';
import { filterPayment } from '../../lib/payment';

import Modal from '../../components/Modal';
import TableView from '../../components/TableView';
import Responsive from '../../components/Responsive';

import EntityItem from '../Entity/Item';

const moment = require('moment-timezone');

const LIMIT_SIZE = 40;
const styles = {
  infoItem: {
    margin: '0.2rem 0',
    minWidth: 240,
    flex: 1
  },
  infoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    backgroundColor: '#fff'
  }
};

@inject('store')
@graphql(gql`query ChargeStatusQuery($id: ID!, $limit: Int, $paymentsLimit: Int) {
  node(id: $id) @connection(key: "Charge", filter: ["id"]) {
    ... on Charge {
      id: dbId
      name
      expired
      payableExpired
      amount
      dateLimit
      hasFine
      hasInterest
      hasAnticipationDiscount
      fineValue
      interestValue
      anticipationDiscountValue
      fineType
      interestType
      anticipationDiscountType
      anticipationDiscountFinalDate
      currency
      hasInterest
      interestValue
      interestType
      message {
        id: dbId
        created
      }
      invoice {
        id: dbId
        sendAt
      }
      entities (orderBy: {column: PAYMENT_STATUS }, limit: $limit) {
        nodes {
          id: dbId
          fullname
          picture {
            id: dbId
            uri
            key
          }
        }
      }
      payments (limit: $paymentsLimit) {
        nodes {
          id: dbId
          status
          method
          installments
          created
          link
          lastStatusUpdate
          externalDetails {
            isExternalSource
            amountToPay
            updatedPaidAmount
            amountToReceive
            installments
            updatedStatus
          }
          entity {
            id: dbId
            fullname
            picture {
              id: dbId
              uri
              key
            }
          }
          user {
            id: dbId
            ... EntityItemUser
          }
        }
      }
    }
  }
}
${EntityItem.fragments.user}
`, {
  options: ownProps => ({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: ownProps.charge.id,
      limit: parseInt((ownProps.charge && ownProps.charge.message && ownProps.charge.message.recipients.totalCount) || LIMIT_SIZE, 10),
      paymentsLimit: parseInt((ownProps.charge && ownProps.charge.allPayments && ownProps.charge.allPayments.totalCount) || 100, 10),
    }
  })
})
@observer
export default class ChargeStatus extends Responsive {
  static fragments = {
    charge: gql`
      fragment ChargeStatus on Charge {
        id: dbId
        message {
          id: dbId
          recipients {
            totalCount
          }
        }
        allPayments: payments {
          totalCount
        }
      }
      `
  }

  renderStatus = (paymentStatus) => {
    const status = { text: __('Not Paid'), icon: 'times', color: '#f03e3e' };

    switch (paymentStatus) {
      case 'PAID':
        status.name = 'check';
        status.color = '#37b24d';
        status.backgroundColor = 'rgb(55, 178, 77, 0.4)';
        status.text = __('Payment confirmed');
        break;

      case 'PENDING':
        status.name = 'clock';
        status.color = '#f59f00';
        status.backgroundColor = 'rgb(245, 159, 0, 0.4)';
        status.text = __('Pending payment');
        break;

      case 'FAILED':
        status.name = 'times';
        status.color = '#f03e3e';
        status.text = __('Payment failed');
        break;

      case 'CANCELED':
        status.name = 'hand holding usd';
        status.color = '#212529';
        status.text = __('Payment refunded');
        break;

      case 'WITHDRAW':
        status.name = 'dollar sign';
        status.color = '#0069FF';
        status.backgroundColor = 'rgb(0, 106, 255, 0.4)';
        break;

      case 'EXPIRED':
        status.name = 'circle xmark';
        status.color = '#00000099';
        status.text = __('Charge expired');
        break;

      case 'OVERDUE':
        status.name = 'clock';
        status.text = __('Charge overdue');
        break;

      default:
        status.name = 'paper plane';
        delete status.color;
        status.text = __('Charge sent');
        break;
    }

    return (
      <div style={{ color: status.color, fontWeight: 'bold' }}>
        <Icon name={status.name} />
        {status.text}
      </div>
    );
  }

  renderPaymentMethod = (method) => {
    if (!method) return null;

    const methods = {
      CARD: __('Credit Card'),
      BOLETO: __('Boleto'),
      PIX: __('Pix'),
      EXTERNAL: __('Manual')
    };

    return methods[method];
  }

  renderColumns = () => [
    __('Entity'),
    __('Status'),
    __('Payment Method'),
    __('Installments'),
    __('Received amount'),
    __('Last Update')
  ]

  renderRowCells = (entity) => {
    const { store } = this.props;
    const paymentInfo = filterPayment(entity.payments, this.props.charge, true, store.currentOrganization.timezone);
    const payment = paymentInfo && (paymentInfo.ignoringDiscount || paymentInfo.ignoringExpiredBoleto) ? null : paymentInfo;
    const charge = this.props.data.node;

    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    return ([
      <div><EntityItem entity={entity} user={payment && payment.user} limit noFullname /></div>,
      this.renderStatus((payment && payment.status) || (charge.expired && (charge.payableExpired ? 'OVERDUE' : 'EXPIRED'))),
      this.renderPaymentMethod(payment && payment.method),
      <div>{payment ? payment.installments : '-'}</div>,
      <div>{payment ? this.displayAmount(payment.externalDetails.updatedPaidAmount, charge.currency) : '-'}</div>,
      payment && utils.simpleDate(payment.lastStatusUpdate, true, 'DD/MM, HH:mm', lang)
    ]);
  };

  renderRows = (row, i) => (
    <Table.Row key={i} data-id={row.id || row.code}>
      {
        this.renderRowCells(row, i).map((cell, j) => <Table.Cell key={j} collapsing={j === 2}>{cell}</Table.Cell>)
      }
    </Table.Row>
  )

  renderLimitDate = (dateLimit) => {
    const formattedDate = moment.utc(dateLimit).format('Y-MM-DD HH:mm:ss');

    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    return utils.simpleDate(formattedDate, true, 'DD/MM/YYYY', lang);
  }

  displayAmount = (amount, currency, type) => (
    !type || type === 'AMOUNT' ?
      amount.toLocaleString('pt-BR', { style: 'currency', currency }) :
      `${amount}%`
  );

  render() {
    const { data, store, charge } = this.props;
    if ((data.loading && !data.node) || data.error || !data.node) return <Modal loading />;

    const lang = store.app.locale ? (store.app.locale === 'pt-BR' ? 'pt' : store.app.locale) : 'en';
    const exportLink = `${store.app.url}/csv/charge/payments?` +
      `limit=1000&charge_id=${charge.id}&access_token=${encodeURIComponent(store.access_token)}&tz_offset=${-(new Date().getTimezoneOffset())}&locale=${lang}`;
    const rawEntities = data && data.node && data.node.entities && data.node.entities.nodes;
    const payments = data && data.node && data.node.payments && data.node.payments.nodes;
    const entities = rawEntities.map(entity => ({
      ...entity,
      payments: payments && payments.filter(payment => payment.entity.id === entity.id)
    }));

    payments.forEach((payment) => {
      if (entities.find(entity => entity.id === payment.entity.id)) return;

      entities.push({
        ...payment.entity,
        payments: payments && payments.filter(p => p.entity.id === payment.entity.id)
      });
    });

    const {
      dateLimit,
      amount,
      message,
      invoice,
      hasFine,
      fineValue,
      currency,
      fineType,
      hasAnticipationDiscount,
      anticipationDiscountValue,
      anticipationDiscountType,
      anticipationDiscountFinalDate,
      hasInterest,
      interestValue,
      interestType
    } = data.node;

    const info = [
      { title: __('Charge amount'), content: amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
      { title: __('Expiration Date'), content: this.renderLimitDate(dateLimit) },
      { title: __('Sending Date'), content: <span style={{ textDecoration: !message && 'line-through' }}>{utils.simpleDate(message ? message.created : invoice.sendAt, true, 'DD/MM/YYYY', lang)}</span> },
      {
        title: __('Anticipation Discount'),
        content: hasAnticipationDiscount ?
          `${this.displayAmount(anticipationDiscountValue, currency, anticipationDiscountType)} ${__('until')} ${anticipationDiscountFinalDate}`
          : __('None')
      },
      { title: __('Fine'), content: hasFine ? this.displayAmount(fineValue, currency, fineType) : __('None') },
      { title: __('Daily interest'), content: hasInterest ? this.displayAmount(interestValue, currency, interestType) : __('No') }
    ];

    const modalStyle = !this.isMobile() ? { minWidth: '80%', marginLeft: '10%', marginRight: '10%', left: 0 } : {};

    return (
      <Modal id="ChargeStatus" open size="medium" style={modalStyle} closeIcon="times close" onClose={() => this.props.store.appends.pop()}>
        <Modal.Header>
          {__('Charge details') + `: ${data.node.name}`}
        </Modal.Header>
        <Modal.Content style={{ overflow: 'scroll', maxHeight: '480px', display: this.isMobile() ? 'grid' : 'block' }}>
          <Message style={styles.infoContainer}>
            {info.map(({ title, content }) => (
              <div style={styles.infoItem}>
                <strong>{title}:</strong> {content}
              </div>
            ))}
          </Message>
          <TableView
            celled
            source={entities}
            renderColumns={this.renderColumns()}
            renderRows={this.renderRows}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            data-action="export-charge-status"
            icon="cloud download"
            content={__('Export')}
            as="a"
            href={exportLink}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
