import React from 'react';
import { Link } from 'react-router';
import { Icon, Popup, Dropdown, Segment, Menu, Loader, Button, Container, Label, Message } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { filter } from 'graphql-anywhere';

import ChargeStatus from './Status';

import Calendar from '../../components/Calendar';
import TableView from '../../components/TableView';
import Input from '../../components/Input';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import Page from '../../components/Page';
import Modal from '../../components/Modal';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const PAGE_SIZE = 15;

const limitOptions = [
  { text: __('Last %s days', 7), value: '7' },
  { text: __('Last %s days', 15), value: '15' },
  { text: __('Last %s days', 30), value: '30' },
  { text: __('Last week'), value: 'week' },
  { text: __('Last month'), value: 'month' },
  { text: __('This month'), value: 'thisMonth' }
];

const styles = {
  dropdownItem: {
    height: 65,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    cursor: 'pointer',
  }
};

const getPaymentStatus = (payments, numEntitiesToNotify, expired, payableExpired) => {
  const isAllCanceled = !numEntitiesToNotify && !!payments.totalCount && payments.nodes.every(item => item.status === 'CANCELED');
  if (isAllCanceled) return { text: __('Refunded'), color: '' };
  if (!numEntitiesToNotify) return { text: __('Complete'), color: 'green' };
  if (!expired) return { text: __('Pending (sing)'), color: 'yellow' };
  return payableExpired ? { text: __('Overdue'), color: 'red' } : { text: __('Expired (fem)'), color: 'gray' };
};

const endOfThisMonth = moment().endOf('month');
const startOfThisMonth = moment().startOf('month');
const defaultFilter = {
  dateIntervalOffset: moment().diff(endOfThisMonth, 'days'),
  dateIntervalLimit: moment().diff(startOfThisMonth, 'days'),
  type: 'thisMonth'
};

@inject('store', 'client')
@graphql(gql`query ChargeChargesQuery (
  $organizationId: ID!,
  $limit: Int,
  $offset: Int,
  $search: String,
  $dateIntervalLimit: Int,
  $dateIntervalOffset: Int
  ) {
  node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
    ... on Organization {
      id: dbId
      charges(limit: $limit, offset: $offset, search: $search, orderBy: { column: DATE_LIMIT, direction: DESC }, dateInterval: { interval: DAY, limit: $dateIntervalLimit, offset: $dateIntervalOffset, column: DATE_LIMIT }) {
        totalCount
        nodes {
          id: dbId
          name
          amount
          dateLimit
          expired
          payableExpired
          messageId
          selected
          canRemind
          dateLimit
          created
          payments(status: [PAID,CANCELED]) {
            totalCount
            nodes {
              id: dbId
              status
            }
          }
          entities {
            totalCount
          }
          ...ChargeStatus
        }
      }
    }
  }
}
${ChargeStatus.fragments.charge}
  `, {
  options: ownProps => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      organizationId: ownProps.params.organization_id,
      limit: parseInt(ownProps.location.query.limit || PAGE_SIZE, 10),
      offset: parseInt(((ownProps.location.query.p || 1) - 1) * (ownProps.location.query.limit || PAGE_SIZE), 10),
      search: ownProps.location.query.search || '',
      dateIntervalLimit: parseInt(ownProps.location.query.dateIntervalLimit || defaultFilter.dateIntervalLimit, 10),
      dateIntervalOffset: parseInt(ownProps.location.query.dateIntervalOffset || defaultFilter.dateIntervalOffset, 10),
    }
  })
})
@graphql(gql`
mutation updatePendingCharge($updateChargeInput: UpdateChargeInput!) {
  updateCharge(input: $updateChargeInput) {
    charge {
      id: dbId
    }
  }
}`, {
  name: 'updatePendingCharge',
  options: {
    refetchQueries: ['MessageNodeEntityQuery', 'MessageEntityHistoryQuery']
  }
})
@observer
export default class ChargeCharges extends Page {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      filter: props.location.query.filter || defaultFilter.type,
      columns: [
        { value: 'date', title: __('Sending Date'), visible: true },
        { value: 'title', title: __('Title'), visible: true },
        { value: 'amount', title: __('Amount'), visible: true },
        { value: 'payments', title: __('Payments'), visible: true },
        { value: 'status', title: __('Status'), visible: true },
        { value: 'date_limit', title: __('Expiration Date'), visible: true }
      ]
    };

    this.mobile = this.isMobile();
  }

  request = (paymentId) => {
    this.setState({ loading: true });
    return this.props.mutate({
      variables: {
        deleteChargePayment: {
          id: paymentId,
        }
      }
    }).then(() => {
      this.props.store.snackbar = { active: true, message: __('Charge was refunded'), success: true };
      this.setState({ loading: false, closedModal: true });
      this.props.store.appends.pop();
    }).catch((err) => {
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
      this.setState({ loading: false, closedModal: true });
    });
  }


  renderChargeColumns = () => {
    const columns = [];

    if (this.state.columns.find(column => column.value === 'date').visible) columns.push(__('Sending Date'));
    if (this.state.columns.find(column => column.value === 'title').visible) columns.push(__('Title'));
    if (this.state.columns.find(column => column.value === 'amount').visible) columns.push(__('Amount'));
    if (this.state.columns.find(column => column.value === 'payments').visible) columns.push(__('Payments'));
    if (this.state.columns.find(column => column.value === 'status').visible) columns.push(__('Status'));
    if (this.state.columns.find(column => column.value === 'date_limit').visible) columns.push(__('Expiration Date'));

    columns.push(null);
    columns.push(null);

    return columns;
  }

  handleCalendarClick = (start, end) => {
    let startDate = start;
    let endDate = end;

    if (endDate && startDate) {
      if (startDate > endDate) {
        startDate = [endDate, endDate = startDate][0];
      }

      if (this.dropdown) this.dropdown.close();

      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
      endDate.setSeconds(0);
      endDate.setMilliseconds(0);

      const now = moment()
        .hour(12)
        .minute(0)
        .second(0)
        .millisecond(0);
      const dateIntervalOffset = moment(endDate).dayOfYear() === now.dayOfYear() ? 0 : now.diff(moment(endDate), 'days');
      const dateIntervalLimit = startDate.getTime() === endDate.getTime() ? 1 : now.diff(moment(startDate), 'days');

      this.onParameterChange({ dateIntervalOffset: dateIntervalOffset.toString(), dateIntervalLimit: dateIntervalLimit.toString(), custom: true, filter: '' });
    }
  }

  handleDropdownClick = ({ value }) => {
    let dateIntervalOffset;
    let dateIntervalLimit;
    let filter = '';

    if (value === 'week') {
      filter = 'week';
      const endOfLastWeek = moment().startOf('week').subtract(1, 'days');
      const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week');
      dateIntervalOffset = moment().diff(endOfLastWeek, 'days');
      dateIntervalLimit = moment().diff(startOfLastWeek, 'days');
    } else if (value === 'month') {
      filter = 'month';
      const endOfLastMonth = moment().startOf('month').subtract(1, 'days');
      const startOfLastMonth = moment().subtract(1, 'month').startOf('month');
      dateIntervalOffset = moment().diff(endOfLastMonth, 'days');
      dateIntervalLimit = moment().diff(startOfLastMonth, 'days');
    } else if (value === 'thisMonth') {
      filter = 'thisMonth';
      const endOfThisMonth = moment().endOf('month');
      const startOfThisMonth = moment().startOf('month');
      dateIntervalOffset = moment().diff(endOfThisMonth, 'days');
      dateIntervalLimit = moment().diff(startOfThisMonth, 'days');
    }
    this.setState({ filter });
    this.onParameterChange({ dateIntervalOffset: (dateIntervalOffset || 0).toString(), dateIntervalLimit: (dateIntervalLimit || value).toString(), custom: false, filter });
  }

  onParameterChange = (data) => {
    const parameters = Object.keys(data).map(name => ({ name, value: data[name] }));

    this.onMultipleParametersChange(parameters);
  }

  remindPending = (numEntitiesToNotify, charge) => {
    const { updatePendingCharge, store } = this.props;
    const { canRemind } = charge;

    if (!canRemind) {
      store.snackbar = { active: true, message: __('A reminder has already been sent for this charge today'), success: false };
      return;
    }

    store.appends.push(
      <Modal
        id="RemindPendingConfirm"
        size="small"
        onClose={() => store.appends.pop()}
        header={__('Remind pending (%s) confirm', numEntitiesToNotify)}
        content={<Message warning>{__('Once sent, it can’t be undone.')}</Message>}
        actions={[
          <Button
            data-action="cancel"
            key={0}
            basic
            floated="left"
            content={__('Cancel')}
            onClick={() => store.appends.pop()}
          />,
          <Button
            data-action="confirm"
            key={1}
            primary
            content={__('Confirm')}
            onClick={() => {
              store.appends.pop();

              updatePendingCharge({
                variables: {
                  updateChargeInput: {
                    id: charge.id,
                    remindPending: {
                      remindAll: true
                    }
                  }
                }
              }).then(() => {
                store.snackbar = { active: true, message: __('Reminders sent'), success: true };
              }).catch((err) => {
                store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
              });
            }}
          />
        ]}
      />
    );
  }
  renderChargeRowCells = (charge, i, len) => {
    const { id, created, name, amount, payments, entities, messageId, expired, payableExpired, dateLimit } = charge;
    const { store } = this.props;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    const date = utils.simpleDate(created, true, 'L HH:mm', lang);
    const date_limit = dateLimit ? utils.simpleDate(dateLimit, true, 'L', lang) : '-';
    const exportLink = `${store.app.url}/csv/charge/payments?` +
      `limit=${entities.totalCount}&charge_id=${id}&access_token=${encodeURIComponent(store.access_token)}&tz_offset=${-(new Date().getTimezoneOffset())}&locale=${lang}`;
    const numEntitiesToNotify = entities.totalCount - payments.totalCount;
    const columns = [];
    const paymentsCanceled = !!payments.totalCount && payments.nodes.every(item => item.status === 'CANCELED');

    if (this.state.columns.find(column => column.value === 'date').visible) columns.push(date);
    if (this.state.columns.find(column => column.value === 'title').visible) columns.push(utils.renderLongText(name));
    if (this.state.columns.find(column => column.value === 'amount').visible) columns.push(`${amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`);
    if (this.state.columns.find(column => column.value === 'payments').visible) {
      columns.push(<span color={!numEntitiesToNotify ? 'green' : (expired || paymentsCanceled ? 'red' : '')}>
        {
          (!numEntitiesToNotify && paymentsCanceled) ? '-' :
            (entities.totalCount > 1 ?
              __('%s of %s paid', payments.totalCount, entities.totalCount)
              :
              __('%s of 1 paid', payments.totalCount)
            )
        }
      </span>);
    }
    if (this.state.columns.find(column => column.value === 'status').visible) {
      const paymentStatus = getPaymentStatus(payments, numEntitiesToNotify, expired, payableExpired);
      columns.push(<Label basic size="small" color={paymentStatus.color}>
        {
          paymentStatus.text
        }
      </Label>);
    }
    if (this.state.columns.find(column => column.value === 'date_limit').visible) columns.push(date_limit);

    columns.push(<Dropdown data-action="open-more-settings" upward={i === len - 1} data-params={id} scrolling button basic icon="ellipsis h alt" className="icon dropdown-menu-right">
      <Dropdown.Menu>
        <Dropdown.Header content={__('Charge ID')} />
        <Dropdown.Item disabled>{id}</Dropdown.Item>
        <Dropdown.Divider />
        {this.props.store.currentEntity.type === 'ADMIN' && messageId && <Dropdown.Item as={Link} to={`/organizations/${store.currentOrganization.id}/messages/${messageId}`}><Icon name="envelope" />{__('See message')}</Dropdown.Item>}
        {!!numEntitiesToNotify && !expired && <Dropdown.Item onClick={() => this.remindPending(numEntitiesToNotify, charge)}><Icon name="bell exclamation" /> {__('Remind pending (%s)', numEntitiesToNotify)}</Dropdown.Item>}
        <Dropdown.Item as="a" href={exportLink}><Icon name="cloud download" />{__('Export')}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>);

    columns.push(
      <Popup
        trigger={
          <Button
            basic
            icon="eye"
            onClick={() => store.appends.push(<ChargeStatus charge={charge} />)}
          />
        }
        content={__('See details')}
      />
    );

    return columns;
  }

  renderFilter = () => {
    const { data, location } = this.props;
    const { custom, dateIntervalLimit, dateIntervalOffset } = location.query;
    const { filter } = this.state;

    let startDate = null;
    let endDate = null;
    let selectedLimit;

    if (!custom && filter === 'week') {
      const endOfLastWeek = moment().startOf('week').subtract(1, 'days');
      const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week');

      endDate = new Date(endOfLastWeek);
      startDate = new Date(startOfLastWeek);
      selectedLimit = limitOptions.find(option => option.value === 'week');
    } else if (!custom && filter === 'month') {
      const endOfLastMonth = moment().startOf('month').subtract(1, 'days');
      const startOfLastMonth = moment().subtract(1, 'month').startOf('month');

      endDate = new Date(endOfLastMonth);
      startDate = new Date(startOfLastMonth);
      selectedLimit = limitOptions.find(option => option.value === 'month');
    } else if (!custom && filter === 'thisMonth') {
      const endOfThisMonth = moment().endOf('month');
      const startOfThisMonth = moment().startOf('month');

      endDate = new Date(endOfThisMonth);
      startDate = new Date(startOfThisMonth);
      selectedLimit = limitOptions.find(option => option.value === 'thisMonth');
    } else {
      endDate = new Date(moment().subtract(dateIntervalOffset || 0, 'days'));
      startDate = new Date(moment().subtract(dateIntervalLimit || 30, 'days'));

      if (custom) {
        selectedLimit = {
          text: __('Custom'),
        };
      } else {
        selectedLimit = limitOptions[limitOptions.findIndex(option => option.value === dateIntervalLimit)] || limitOptions[2];
      }
    }

    startDate.setHours(12);
    startDate.setMinutes(0);
    startDate.setSeconds(0);

    endDate.setHours(12);
    endDate.setMinutes(0);
    endDate.setSeconds(0);

    return (
      <Container fluid key={0} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Icon name="filter" />
          <strong>{__('Filter expiration date by:')}</strong>
          <span style={{ color: '#4a90e2', marginLeft: '10px' }}>
            <Dropdown ref={(c) => { this.dropdown = c; }} inline data-action={'open-more-settings'} basic text={!data.loading && selectedLimit.text} loading={data.loading}>
              <Dropdown.Menu onClick={e => e.stopPropagation()} style={{ transform: 'translateX(-10%)' }}>
                <div style={{ display: 'flex', color: 'black', backgroundColor: 'white', padding: '4px 0px', borderRadius: '4px', boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)', border: 'solid 1px #e9ecef' }}>
                  <div style={{ marginBottom: 'auto', marginTop: 'auto' }}>
                    {
                      limitOptions.map((option, i) =>
                        <Dropdown.Item
                          key={i}
                          {...option}
                          style={{ ...styles.dropdownItem, backgroundColor: option === selectedLimit ? 'rgba(0, 118, 255, 0.07)' : 'white', }}
                          active={option === selectedLimit}
                          onClick={() => this.handleDropdownClick(option)}
                        />
                      )
                    }
                  </div>
                  <div style={{ padding: '5px 16px', borderLeft: 'solid 1px #e9ecef' }}>
                    <p style={{ marginBottom: '8px', marginTop: '4px', fontSize: '1.1em' }}>{__('Period')}</p>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '10px' }}>
                        <p style={{ marginBottom: '-0.4em' }}>{__('Begin')}</p>
                        <Calendar
                          withBorder
                          withPadding
                          calendarStyles
                          headerAlwaysOn
                          selectedDt={startDate}
                          maxDate={new Date(endDate.getTime() - (24 * 60 * 60 * 1000))}
                          minDate={new Date(2020, 0, 1)}
                          onSelect={date => this.handleCalendarClick(date.selectedDt, endDate)}
                        />
                      </div>
                      <div>
                        <p style={{ marginBottom: '-0.4em' }}>{__('End')}</p>
                        <Calendar
                          withBorder
                          withPadding
                          calendarStyles
                          headerAlwaysOn
                          selectedDt={endDate}
                          minDate={startDate}
                          onSelect={date => this.handleCalendarClick(startDate, date.selectedDt)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
      </Container>
    );
  }

  renderTableFilter = () => {
    const { columns } = this.state;

    return (
      <div style={{ display: 'flex', marginTop: '14px' }}>
        <Segment style={{ padding: '2px', margin: 0, border: 0, background: 'none', boxShadow: 'none', width: '100%' }}>
          <Menu secondary>
            <Menu compact>
              <Dropdown
                text={__('Columns')}
                data-params="columns"
                item
              >
                <Dropdown.Menu>
                  {columns.map((column, i) => (
                    <Dropdown.Item
                      style={{ display: 'flex' }}
                      onClick={() => {
                        columns[i].visible = !column.visible;

                        this.setState({ columns });
                      }}
                    >
                      <ColoredCheckbox
                        data-value={column.value}
                        label={column.title}
                        checked={column.visible}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
            <Input
              data-action="search-charge"
              onChange={(e, { value }) => this.onUniqueParameterChange('search', value)}
              placeholder={__('Search by title')}
              style={{ margin: 'auto 3px' }}
              wait
              initialValue={this.props.location.query.search || ''}
            />
          </Menu>

        </Segment>
      </div>
    );
  }

  render() {
    const { data, location } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    const { nodes, totalCount } = data.node.charges;

    return (
      <div id="ChargeCharges" style={{ marginTop: 32, marginLeft: 10 }}>
        <div>
          {this.renderFilter()}
          {this.renderTableFilter()}
        </div>
        <TableView
          style={{
            textAlign: 'left'
          }}
          renderColumns={this.renderChargeColumns()}
          renderRowCells={this.renderChargeRowCells}
          source={nodes}
          pagination={{
            loading: data.loading,
            totalCount,
            query: location.query,
            limit: PAGE_SIZE
          }}
          onLoadMore={this.eventReload}
        />
      </div>
    );
  }
}
